import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Toolbar, OutlinedInput, InputAdornment } from '@mui/material';

import Iconify from '../../../components/Iconify';
import { SEARCH_USER } from '../../../lang/en';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  justifyContent: 'space-between',
  margin: theme.spacing('15px', 0, 0, 0),
  // paddingLeft: theme.spacing(4),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ filterName, onFilterName, position = '' }) {
  return (
    <RootStyle className={position}>
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder={`${SEARCH_USER}...`}
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}
