import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styleAllPages.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { Box, CircularProgress, Container, Stack, Button, TextField } from '@mui/material';

import { Alert, Page } from '../components';
import { useAppContext } from '../context/app-context';
import { SAVE_PLAN, SAVE } from '../lang/en';

const PlanSave = () => {
  const { authFetch, showAlert, displayAlert } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState({
    User: {
      title: 'User',
      items: [],
    },
  });

  // const [timeState, setTimeState] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const { data } = await authFetch.get(`location`);
        const { locations } = data;

        const newStateLocations = locations.reduce((acc, curr) => {
          const { locationName } = curr;
          acc[locationName] = { title: locationName, items: [] };
          return acc;
        }, {});
        // const newTimeStateLocations = locations.reduce((acc, curr) => {
        //   const { locationName } = curr;
        //   acc[locationName] = { title: locationName, time: '' };
        //   return acc;
        // }, {});
        setState((prev) => ({ ...prev, ...newStateLocations }));
        // setTimeState((prev) => ({ ...prev, ...newTimeStateLocations }));
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchLocations();

    const fetchUsers = async () => {
      try {
        const { data } = await authFetch.get(`user?limit=1000`);
        const { users } = data;

        const newStateUsers = users.map((user) => ({ id: user._id, nameItem: `${user?.firstName} ${user?.lastName}` }));

        setState((prev) => ({ ...prev, User: { title: 'Users', items: newStateUsers } }));
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchUsers();
  }, []);

  const saveTodayPlan = async () => {
    try {
      const todayPlanToUpdate = { users: state };
      const todayPlanSaved = await authFetch.patch('today-plan/633d6470e75afe13dd453773', todayPlanToUpdate);

      if (todayPlanSaved !== undefined) {
        setTimeout(() => {
          navigate('/dashboard/plan');
        }, 3000);
        displayAlert('success', todayPlanSaved?.data?.msg);
      }
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }
    // Creating a copy of item before removing it from state
    const itemCopy = { ...state[source.droppableId].items[source.index] };

    setState((prev) => {
      prev = { ...prev };
      // Remove from previous items array
      prev[source.droppableId].items.splice(source.index, 1);

      // Adding to new items array location
      prev[destination.droppableId].items.splice(destination.index, 0, itemCopy);

      return prev;
    });
  };

  return (
    <>
      {showAlert && <Alert />}
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title={SAVE_PLAN}>
            <Container>
              <div className="Plan">
                <DragDropContext onDragEnd={handleDragEnd}>
                  {_.map(state, (data, key) => {
                    return (
                      <div key={key} className={key === 'User' ? `column users` : 'column'}>
                        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                          <h3>{data.title}</h3>
                          {/* {_.map(
                            timeState,
                            (dataTime, keyTime) =>
                              keyTime === key && (
                                <TextField
                                  label={dataTime.title}
                                  type="text"
                                  fullWidth
                                  value={timeState?.keyTime?.time}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    console.log(keyTime);
                                    console.log(dataTime.title);
                                    setTimeState((prev) => ({
                                      ...prev,
                                      keyTime: { title: dataTime.title, time: e.target.value },
                                    }));
                                  }}
                                />
                              )
                          )} */}
                        </Stack>
                        <Droppable droppableId={key}>
                          {(provided, snapshot) => {
                            return (
                              <div ref={provided.innerRef} {...provided.droppableProps} className={'droppable-col'}>
                                {data.items.map((el, index) => {
                                  return (
                                    <Draggable key={el.id} index={index} draggableId={el.id}>
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            className={`item ${snapshot.isDragging && 'dragging'}`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            {el.nameItem}
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    );
                  })}
                </DragDropContext>
              </div>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Button
                  sx={{ marginTop: '10px' }}
                  variant="contained"
                  onClick={() => {
                    saveTodayPlan();
                  }}
                >
                  {SAVE}
                </Button>
              </Stack>
            </Container>
          </Page>
        </>
      )}
    </>
  );
};

export default PlanSave;
