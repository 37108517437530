import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { CANCEL, CONFIRM } from '../lang/en';

AlertDialogText.propTypes = {
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  text: PropTypes.string,
};

export default function AlertDialogText({ handleClose, handleConfirm, text }) {
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleConfirm}>
            {CONFIRM}
          </Button>
          <Button variant="outlined" onClick={handleClose} color="error">
            {CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
