import * as Yup from 'yup';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import Webcam from 'react-webcam';
import FormData from 'form-data';
import './styles.css';

import { Stack, TextField, FormControl, FormLabel, MenuItem, Select, FormHelperText, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Alert, AlertDialogText } from '../../../components';
import { useAppContext } from '../../../context/app-context';
import {
  HPZ,
  HBP,
  APDT,
  TRANSFR_KM,
  DISTANCE,
  PLACED,
  PLATE_IS_REQUIRED,
  TRANSFER_METHOD_IS_REQUIRED,
  TRANSFER_TYPE_IS_REQUIRED,
  TRANSFER_PLACE_IS_REQUIRED,
  SPECIAL_PRICE_IS_REQUIRED,
  LOCATION_TYPE_IS_REQUIRED,
  CAR_TYPE_IS_REQUIRED,
  MUST_BE_NUMBER,
  TRANSFER,
  SCANN,
  NUMBER_PLATE,
  SELECT_LOCATION,
  SELECT_CAR_TYPE,
  SELECT_TRANSFER_TYPE,
  SELECT_TRANSFER_METHOD,
  COLLECTION,
  DELIVERY,
  TYPE_PRICE,
  CAR_TRANSFERED_6HOURS_TEXT,
} from '../../../lang/en';

const videoConstraints = {
  width: 400,
  height: 200,
  // facingMode: { exact: 'user' },
  facingMode: { exact: 'environment' },
};

const transferTypesNames = {
  hzp: HPZ,
  hbp: HBP,
  apdt: APDT,
};

export default function CarTransferForm() {
  const { showAlert, displayAlert, authFetch, user } = useAppContext();
  const [locations, setLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({});
  const [transferTypes, setTransferTypes] = useState([]);
  const webcamRef = useRef(null);
  const [isSuspectOpen, setIsSuspectOpen] = useState(false);

  const navigate = useNavigate();

  const handleCloseSuspect = () => {
    setIsSuspectOpen(false);
  };
  const handleConfirmSuspect = async () => {
    try {
      const carTransfer = {
        userId: user._id,
        licensePlate: formik.values.licensePlate,
        locationId: formik.values.locationId,
        carType: formik.values.carType,
        transferMethod: formik.values.transferMethod,
        transferType: formik.values.transferType,
        transferPlace: formik.values.transferPlace,
        transferDistance: formik.values.transferDistance,
        acceptSuspect: true,
      };
      const newCarTransfer = await authFetch.post('/car-transfer/', carTransfer);

      setIsSuspectOpen(false);

      if (newCarTransfer?.data?.carTransfer?._id !== undefined) {
        setTimeout(() => {
          navigate('/user/cars-transfered-user', { replace: true });
        }, 3000);
        displayAlert('success', newCarTransfer?.data?.msg);
      }
    } catch (error) {
      setIsSuspectOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const CarTransferSchema = Yup.object().shape({
    licensePlate: Yup.string().required(PLATE_IS_REQUIRED),
    locationId: Yup.string().required(LOCATION_TYPE_IS_REQUIRED),
    carType: Yup.string().required(CAR_TYPE_IS_REQUIRED),
    transferMethod: Yup.string().required(TRANSFER_METHOD_IS_REQUIRED),
    transferType: Yup.string().required(TRANSFER_TYPE_IS_REQUIRED),
    transferPlace: Yup.string().when('transferType', {
      is: (transferType) => transferType === 'presumptive',
      then: Yup.string().required(TRANSFER_PLACE_IS_REQUIRED),
    }),
    transferDistance: Yup.number().when('transferType', {
      is: (transferType) => transferType === 'presumptive',
      then: Yup.number().typeError(MUST_BE_NUMBER).required(SPECIAL_PRICE_IS_REQUIRED).positive(TYPE_PRICE),
    }),
  });

  const formik = useFormik({
    initialValues: {
      userId: user._id,
      licensePlate: '',
      locationId: '',
      carType: '',
      transferMethod: '',
      transferType: '',
      transferPlace: '',
      transferDistance: '',
    },
    validationSchema: CarTransferSchema,
    onSubmit: (values, { setSubmitting }) => {
      (async () => {
        try {
          const carTransfer = {
            userId: user._id,
            licensePlate: formik.values.licensePlate,
            locationId: formik.values.locationId,
            carType: formik.values.carType,
            transferMethod: formik.values.transferMethod,
            transferType: formik.values.transferType,
            transferPlace: formik.values.transferPlace,
            transferDistance: formik.values.transferDistance,
          };
          const newCarTransfer = await authFetch.post('/car-transfer/', carTransfer);

          if (newCarTransfer?.data?.suspected) {
            setIsSuspectOpen(true);
          }
          if (newCarTransfer.data?.carTransfer?._id !== undefined) {
            setTimeout(() => {
              navigate('/user/cars-transfered-user', { replace: true });
            }, 3000);
            displayAlert('success', newCarTransfer?.data?.msg, setSubmitting);
          }
        } catch (error) {
          displayAlert('danger', error?.response?.data?.msg, setSubmitting);
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, isSubmitting } = formik;

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const { data } = await authFetch.get(`location?locationType=withTransfer`);
        const { locations } = data;
        setLocations(locations);

        const currentLocation = locations?.find((location) => location._id === user.locationId);
        setCurrentLocation(currentLocation);
        formik.setFieldValue('locationId', currentLocation?._id);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchCurrentLocation = async () => {
      const currentLocation = locations?.find((location) => location._id === formik.values.locationId);
      setCurrentLocation(currentLocation);
      if (currentLocation?.carType !== undefined) formik.setFieldValue('carType', currentLocation.carType[0].name);

      if (currentLocation !== undefined) formik.setFieldValue('transferMethod', 'collection');

      const currentCarType = currentLocation?.carType?.find((cType) => cType.name === formik.values.carType);
      const currentTransferTypes = currentCarType?.transfer
        ? Object.entries(currentCarType?.transfer).filter(
            (item) => item[0] !== 'base' && item[0] !== 'perkm' && item[1] !== ''
          )
        : null;

      setTransferTypes(currentTransferTypes);
      if (currentTransferTypes !== null) formik.setFieldValue('transferType', currentTransferTypes[0][0]);
    };
    fetchCurrentLocation();

    formik.setFieldValue('transferPlace', '');
    formik.setFieldValue('transferDistance', '');
  }, [formik.values.locationId]);

  useEffect(() => {
    const fetchTransferTypes = async () => {
      const currentCarType = currentLocation?.carType?.find((cType) => cType.name === formik.values.carType);
      const currentTransferTypes = currentCarType?.transfer
        ? Object.entries(currentCarType?.transfer).filter(
            (item) => item[0] !== 'base' && item[0] !== 'perkm' && item[1] !== ''
          )
        : null;

      setTransferTypes(currentTransferTypes);
      if (currentTransferTypes !== null) formik.setFieldValue('transferType', currentTransferTypes[0][0]);
    };
    fetchTransferTypes();
  }, [formik.values.carType]);

  const handleScan = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const body = new FormData();
    body.append('upload', imageSrc);
    body.append('regions', 'ch');

    fetch('https://api.platerecognizer.com/v1/plate-reader/', {
      method: 'POST',
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
      body,
    })
      .then((res) => res.json())
      .then((json) => {
        formik.setFieldValue('licensePlate', (json?.results[0]?.plate).toUpperCase());
      })
      .catch((error) => {
        displayAlert('danger', error?.response?.data?.msg);
      });
  }, [webcamRef]);

  return (
    <>
      {showAlert && <Alert />}
      <div id="car-scann">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          forceScreenshotSourceSize="true"
          videoConstraints={videoConstraints}
        />
        <Button fullWidth size="large" type="submit" variant="outlined" onClick={handleScan}>
          {SCANN}
        </Button>
      </div>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="licensePlate"
              type="text"
              label={NUMBER_PLATE}
              {...getFieldProps('licensePlate')}
              error={Boolean(touched.licensePlate && errors.licensePlate)}
              helperText={touched.licensePlate && errors.licensePlate}
            />

            <FormControl fullWidth error={Boolean(touched.locationId && errors.locationId)}>
              <FormLabel id="locationId-label">{SELECT_LOCATION}</FormLabel>
              <Select id="select-locationId" {...getFieldProps('locationId')}>
                {locations.map(
                  (location, index) =>
                    location.locationType === 'withTransfer' && (
                      <MenuItem key={index} value={location?._id}>
                        {location?.locationName}
                      </MenuItem>
                    )
                )}
              </Select>
              {Boolean(touched.locationId && errors.locationId) && <FormHelperText>{errors.locationId}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.carType && errors.carType)}>
              <FormLabel id="carType-label">{SELECT_CAR_TYPE}</FormLabel>
              <Select id="select-carType" {...getFieldProps('carType')}>
                {currentLocation?.carType?.map((cType, index) => (
                  <MenuItem key={index} value={cType?.name}>
                    {cType?.name}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(touched.carType && errors.carType) && <FormHelperText>{errors.carType}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.transferMethod && errors.transferMethod)}>
              <FormLabel id="transferMethod-label">{SELECT_TRANSFER_METHOD}</FormLabel>
              <Select id="select-transferMethod" {...getFieldProps('transferMethod')}>
                <MenuItem value="collection">{COLLECTION}</MenuItem>
                <MenuItem value="delivery">{DELIVERY}</MenuItem>
              </Select>

              {Boolean(touched.transferMethod && errors.transferMethod) && (
                <FormHelperText>{errors.transferMethod}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.transferType && errors.transferType)}>
              <FormLabel id="transferType-label">{SELECT_TRANSFER_TYPE}</FormLabel>
              <Select id="select-transferType" {...getFieldProps('transferType')}>
                {transferTypes?.map((transferType, index) => (
                  <MenuItem key={index} value={transferType[0]}>
                    {transferTypesNames[transferType[0]]}
                  </MenuItem>
                ))}

                <MenuItem value="presumptive">{TRANSFR_KM}</MenuItem>
              </Select>

              {Boolean(touched.transferType && errors.transferType) && (
                <FormHelperText>{errors.transferType}</FormHelperText>
              )}
            </FormControl>

            {getFieldProps('transferType').value === 'presumptive' && (
              <>
                <TextField
                  fullWidth
                  type="text"
                  label={PLACED}
                  {...getFieldProps('transferPlace')}
                  error={Boolean(touched.transferPlace && errors.transferPlace)}
                  helperText={touched.transferPlace && errors.transferPlace}
                />
                <TextField
                  fullWidth
                  autoComplete="Distance"
                  type="number"
                  label={DISTANCE}
                  {...getFieldProps('transferDistance')}
                  error={Boolean(touched.transferDistance && errors.transferDistance)}
                  helperText={touched.transferDistance && errors.transferDistance}
                />
              </>
            )}
          </Stack>
          <Stack mt={3}>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              {TRANSFER}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      {isSuspectOpen && (
        <AlertDialogText
          handleClose={handleCloseSuspect}
          handleConfirm={handleConfirmSuspect}
          text={CAR_TRANSFERED_6HOURS_TEXT}
        />
      )}
    </>
  );
}
