import { createContext, useReducer, useContext } from 'react';
import axios from 'axios';
import reducer from './reducer';
import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGIN_USER_BEGIN,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from './actions';
import { LOGIN_ALERT_DANGER } from '../lang/en';

const token = localStorage.getItem('token');
const user = localStorage.getItem('user');

const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: '',
  alertType: '',
  user: user ? JSON.parse(user) : null,
  token,
};
// const urlLogin = 'http://172.20.10.7:5000/api/v1/auth/login';
// const urlLogin = 'http://192.168.1.111:5000/api/v1/auth/login';
const urlLogin = 'https://rhrcarcare-api.herokuapp.com/api/v1/auth/login';

const AppContext = createContext();
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const authFetch = axios.create({
    // baseURL: 'http://172.20.10.7:5000/api/v1',
    // baseURL: 'http://192.168.1.111:5000/api/v1',
    baseURL: 'https://rhrcarcare-api.herokuapp.com/api/v1',
  });
  authFetch.interceptors.request.use(
    (config) => {
      config.headers.common.Authorization = `Bearer ${state.token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logOut();
      }
      return Promise.reject(error);
    }
  );

  const displayAlert = (alertType, alertText, setSubmitting) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    dispatch({ type: DISPLAY_ALERT, payload: { alertType, alertText } });
    clearAlert(setSubmitting);
  };
  const clearAlert = (setSubmitting) => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
      if (setSubmitting) setSubmitting(false);
    }, 3000);
  };
  const clearAlertLogin = (setSubmitting) => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
      if (setSubmitting) setSubmitting(false);
    }, 2000);
  };

  const addUserToLocalStorage = ({ user, token }) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
  };
  const removeUserToLocalStorage = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  const loginUser = async (currentUser, setSubmitting) => {
    dispatch({ type: LOGIN_USER_BEGIN });
    try {
      const { data } = await axios.post(urlLogin, currentUser);
      const { user, token } = data;
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { user, token },
      });

      addUserToLocalStorage({ user, token });
    } catch (error) {
      dispatch({
        type: LOGIN_USER_ERROR,
        payload: { msg: LOGIN_ALERT_DANGER },
      });
    }
    clearAlertLogin(setSubmitting);
  };

  const logOut = () => {
    dispatch({ type: LOGOUT_USER });
    removeUserToLocalStorage();
  };

  const updateUser = async (userId, editedUser, setSubmitting) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const { data } = await authFetch.patch(`user/${userId}`, editedUser);
      let { user, token } = state;

      if (user._id === userId) {
        ({ user, token } = data);
      }

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, token },
      });
      addUserToLocalStorage({ user, token });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error?.response?.data?.msg },
        });
      }
    }
    clearAlert(setSubmitting);
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        loginUser,
        logOut,
        updateUser,
        authFetch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { initialState, AppProvider, useAppContext };
