import { Navigate, useRoutes } from 'react-router-dom';

import DashboardLayoutAdmin from './layouts/dashboard/DashboardLayoutAdmin';
import DashboardLayoutAccountant from './layouts/dashboard/DashboardLayoutAccountant';
import DashboardLayoutManager from './layouts/dashboard/DashboardLayoutManager';
import DashboardLayoutUser from './layouts/dashboard/DashboardLayoutUser';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import {
  User,
  Page404,
  Login,
  ResetPassword,
  DashboardApp,
  NewUser,
  Profile,
  CheckInOut,
  CheckInOutsAdmin,
  NewCheckInOut,
  CarWash,
  CarsWashedUser,
  NewCarWash,
  CarsWashed,
  CarTransfer,
  CarsTransferedUser,
  NewCarTransfer,
  CarsTransfered,
  AllLocations,
  NewLocation,
  Plan,
  PlanSave,
  Payroll,
  Payrolls,
} from './pages';

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayoutAdmin />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'profile', element: <Profile /> },

        { path: 'check-in-out', element: <CheckInOut /> },
        { path: 'check-in-outs', element: <CheckInOutsAdmin /> },
        { path: 'new-check-in-out', element: <NewCheckInOut /> },

        { path: 'cars-washed', element: <CarsWashed /> },
        { path: 'new-car-wash', element: <NewCarWash /> },
        { path: 'car-wash', element: <CarWash /> },
        { path: 'cars-washed-user', element: <CarsWashedUser /> },

        { path: 'cars-transfered', element: <CarsTransfered /> },
        { path: 'new-car-transfer', element: <NewCarTransfer /> },
        { path: 'car-transfer', element: <CarTransfer /> },
        { path: 'cars-transfered-user', element: <CarsTransferedUser /> },

        { path: 'plan', element: <Plan /> },
        { path: 'save-plan', element: <PlanSave /> },

        { path: 'payroll', element: <Payroll /> },
        { path: 'payrolls', element: <Payrolls /> },

        { path: 'user', element: <User /> },
        { path: 'new-user', element: <NewUser /> },

        { path: 'locations', element: <AllLocations /> },
        { path: 'new-location', element: <NewLocation /> },
      ],
    },
    {
      path: '/accountant',
      element: <DashboardLayoutAccountant />,
      children: [
        { path: 'profile', element: <Profile /> },
        { path: 'payroll', element: <Payroll /> },
        { path: 'payrolls', element: <Payrolls /> },
      ],
    },
    {
      path: '/manager',
      element: <DashboardLayoutManager />,
      children: [
        { path: 'profile', element: <Profile /> },
        { path: 'check-in-out', element: <CheckInOut /> },

        { path: 'cars-washed', element: <CarsWashed /> },
        { path: 'new-car-wash', element: <NewCarWash /> },
        { path: 'car-wash', element: <CarWash /> },
        { path: 'cars-washed-user', element: <CarsWashedUser /> },

        { path: 'cars-transfered', element: <CarsTransfered /> },
        { path: 'new-car-transfer', element: <NewCarTransfer /> },
        { path: 'car-transfer', element: <CarTransfer /> },
        { path: 'cars-transfered-user', element: <CarsTransferedUser /> },

        { path: 'plan', element: <Plan /> },
      ],
    },
    {
      path: '/user',
      element: <DashboardLayoutUser />,
      children: [
        { path: 'profile', element: <Profile /> },
        { path: 'check-in-out', element: <CheckInOut /> },
        { path: 'car-wash', element: <CarWash /> },
        { path: 'cars-washed-user', element: <CarsWashedUser /> },
        { path: 'car-transfer', element: <CarTransfer /> },
        { path: 'cars-transfered-user', element: <CarsTransferedUser /> },
        { path: 'plan', element: <Plan /> },
      ],
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
