import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { styled } from '@mui/material/';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { useAppContext } from '../../context/app-context';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayoutAccountant() {
  const [open, setOpen] = useState(false);

  const { user } = useAppContext();

  if (!user) {
    return <Navigate to="/" />;
  }
  if (user?.role === 'admin') {
    return <Navigate to="/dashboard/app" />;
  }
  if (user?.role === 'manager') {
    return <Navigate to="/manager/check-in-out" />;
  }
  if (user?.role === 'user') {
    return <Navigate to="/user/check-in-out" />;
  }

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
