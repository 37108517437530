import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, CircularProgress, Card, Stack, Container, Typography } from '@mui/material';

import { CarTransferForm, EditCarTransferUserForm } from '../sections/@dashboard/carTransfer';
import { Page } from '../components';
import { UPDATE_CAR_TRANSFER, ADD_CAR_TRANSFER, CAR_TRANSFER } from '../lang/en';

const CarTransfer = () => {
  const { state } = useLocation();
  return (
    <Page title={CAR_TRANSFER}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {state?.carTransferId ? UPDATE_CAR_TRANSFER : ADD_CAR_TRANSFER}
          </Typography>
        </Stack>
        <Card style={{ padding: '20px' }}>
          {state?.carTransferId ? (
            <EditCarTransferUserForm carTransferId={state?.carTransferId} />
          ) : (
            <CarTransferForm />
          )}
        </Card>
      </Container>
    </Page>
  );
};
export default CarTransfer;
