import { useState } from 'react';
import { Navigate, Outlet, Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { useAppContext } from '../../context/app-context';
import Iconify from '../../components/Iconify';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const ButtonsStyle = styled('div')(({ theme }) => ({
  position: 'fixed',
  right: '10px',
  bottom: '10px',
  background: 'transparent',
  border: 'none',
}));
const ButtonStyle1 = styled(RouterLink)(({ theme }) => ({
  backgroundColor: '#2065d1',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  border: 'none',
  marginRight: '5px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    color: '#fff',
  },
  '&:hover': {
    backgroundColor: '#103996',
  },
}));
const ButtonStyle2 = styled(RouterLink)(({ theme }) => ({
  backgroundColor: '#47ce1d',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  border: 'none',
  marginRight: '5px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    color: '#fff',
  },
  '&:hover': {
    backgroundColor: '#359517',
  },
}));

export default function DashboardLayoutUser() {
  const [open, setOpen] = useState(false);

  const { user } = useAppContext();

  if (!user) {
    return <Navigate to="/" />;
  }
  if (user?.role === 'admin') {
    return <Navigate to="/dashboard/app" />;
  }
  if (user?.role === 'accountant') {
    return <Navigate to="/accountant/payroll" />;
  }
  if (user?.role === 'manager') {
    return <Navigate to="/manager/check-in-out" />;
  }

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <ButtonsStyle>
        <ButtonStyle1 to="/user/car-wash">
          <Iconify icon="eva:car-fill" />
        </ButtonStyle1>
        <ButtonStyle2 to="/user/car-transfer">
          <Iconify icon="eva:flip-2-fill" />
        </ButtonStyle2>
      </ButtonsStyle>
    </RootStyle>
  );
}
