import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Stack, TextField, IconButton, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Iconify } from '../../../components';
import { useAppContext } from '../../../context/app-context';
import {
  NEW_PASSWORD_IS_REQUIRED,
  NEW_AND_CONFIRM_PASSWORDS,
  NEW_PASSWORD,
  CONFRIM_NEW_PASSWORD,
  RESET_PASSWORD,
  GO_HOME,
} from '../../../lang/en';

const urlResetPassword = 'https://rhrcarcare-api.herokuapp.com/api/v1/auth/reset-password';
const urlParams = new URLSearchParams(window.location.search);

export default function ResetPasswordForm() {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const navigate = useNavigate();
  const { displayAlert } = useAppContext();

  const handleNewShowPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleShowConfirmedPassword = () => {
    setShowConfirmedPassword((show) => !show);
  };

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required(NEW_PASSWORD_IS_REQUIRED),
    confirmedPassword: Yup.string().when('newPassword', {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('newPassword')], NEW_AND_CONFIRM_PASSWORDS),
    }),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmedPassword: '',
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      (async () => {
        try {
          const resetPassord = {
            newPassword: formik.values.newPassword,
            confirmPassword: formik.values.confirmedPassword,
            email: urlParams.get('email'),
            token: urlParams.get('token'),
          };

          const passwordReset = await axios.patch(urlResetPassword, resetPassord);

          if (passwordReset !== undefined) {
            setTimeout(() => {
              navigate('/login', { replace: true });
            }, 3000);
            displayAlert('success', passwordReset?.data?.msg, setSubmitting);
          }
        } catch (error) {
          displayAlert('danger', error?.response?.data?.msg, setSubmitting);
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type={showNewPassword ? 'text' : 'password'}
              label={NEW_PASSWORD}
              {...getFieldProps('newPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleNewShowPassword} edge="end">
                      <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />

            <TextField
              fullWidth
              type={showConfirmedPassword ? 'text' : 'password'}
              label={CONFRIM_NEW_PASSWORD}
              {...getFieldProps('confirmedPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowConfirmedPassword} edge="end">
                      <Iconify icon={showConfirmedPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmedPassword && errors.confirmedPassword)}
              helperText={touched.confirmedPassword && errors.confirmedPassword}
            />

            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              {RESET_PASSWORD}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button to="/" variant="outlined" component={RouterLink} sx={{ mt: 8, width: '50%' }}>
          {GO_HOME}
        </Button>
      </div>
    </>
  );
}
