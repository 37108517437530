import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import './styleAllPages.css';
import FormData from 'form-data';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Grid,
  Card,
  Container,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  Toolbar,
  styled,
  MenuItem,
  TextField,
  tabClasses,
} from '@mui/material';

import { Alert, Page } from '../components';
import { useAppContext } from '../context/app-context';
import { pdfOptionsHourlyPay, pdfOptionsMonthlyPay } from '../utils/pdfOptions';
import {
  FIRST_NAME,
  LAST_NAME,
  USER,
  LOHNABRECHNUNG_EMAIL_SENT,
  POSTAL_CODE,
  STREET,
  PLACE,
  AHV_NUMBER,
  PAYROLL,
  MONTH_YEAR,
  PLACE_DATE,
  ARBEITGEBER,
  WORKER,
  BASICS,
  KANTON,
  BILLINGPROCEDUREG,
  HOURLYPAY,
  TOTAL_HOURS,
  LOHN,
  SATZE,
  PRO_STUNDE,
  PRO_MONAT,
  ARBEITSLOHN,
  HOLIDAYBONUSG,
  BRUTTOLOHN,
  TOTAL_ABZUGE,
  ABZUGE,
  NETTOLOHN,
  SAVE_SEND_EMAIL,
  PDF,
  MONATSLOHN,
} from '../lang/en';

const CustomStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'start',
  padding: theme.spacing(0, 1, 0, 3),
}));

const EMPLOYER = { name: 'RHR Carcare', street: 'Pfingstweidstreet 3', plz: '8005, Zürich' };

const CANTON = {
  Auswählen: 0,
  Aargau: 0.0145,
  'Appenzell AR': 0.016,
  Appenzell_AI: 0.018,
  Baselland: 0.0125,
  Basel_Stadt: 0.0165,
  Bern: 0.015,
  Freiburg: 0.0265,
  Genf: 0.024,
  Glarus: 0.015,
  Graubünden: 0.0165,
  Jura: 0.0265,
  Luzern: 0.0135,
  Neuenburg: 0.021,
  Nidwalden: 0.015,
  Obwalden: 0.014,
  StGallen: 0.018,
  Solothurn: 0.0115,
  Schaffhausen: 0.014,
  Schwyz: 0.013,
  Tessin: 0.0195,
  Thurgau: 0.015,
  Uri: 0.021,
  Waadt: 0.0258,
  Wallis: 0.02519,
  Zug: 0.016,
  Zürich: 0.0112,
};
const BILLINGPROCEDURES = [
  'Auswählen',
  'Vereinfachtes',
  'Ordentliches',
  'Ordentliches mit Quellensteuer',
  'Monatslohn',
];

const HOLIDAYBONUS = {
  Auswählen: 0,
  '8.33%': 0.0833,
  '10.64%': 0.1064,
  '13.04%': 0.1304,
  Kein: 0,
};

const TEXTTAXES = {
  0: 'Kein:',
  0.0833: '8.33%',
  0.1064: '10.64%',
  0.1304: '13.04%',
};

const TAXES = {
  'AHV/IV/EO': 5.3,
  ALV: 1.1,
  KTV: 1.87,
  NBU: 0.51,
  // Quellensteurv: 5.0,
  Quellensteur: 0.25,
  // FAK: 0.3,
};

const addTax = (tax, num) => ((1 + Number(tax)) * Number(num)).toFixed(2);

const sum = (arr) => {
  const newArr = arr.map(Number);
  const sum = newArr.reduce((partialSum, i) => partialSum + i, 0);
  return sum.toFixed(2);
};
const product = (arr) => {
  const newArr = arr.map(Number);
  const product = newArr.reduce((partialProduct, i) => partialProduct * i, 1);
  return product.toFixed(2);
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Payroll = () => {
  const { state } = useLocation();

  const { authFetch, displayAlert, showAlert } = useAppContext();
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('');

  const [monthYear, setMonthYear] = useState(null);
  const [placeDate, setPlaceDate] = useState('');
  const [employer, setEmployer] = useState(EMPLOYER);
  const [worker, setWorker] = useState({ name: '', street: '', plz: '', ahv: '', email: '' });

  const [canton, setCanton] = useState('Zürich');
  const [billingProcedure, setBillingProcedure] = useState('Auswählen');

  const [totalHours, setTotalHours] = useState(0);
  const [hourlyPay, setHourlyPay] = useState(0);
  const [monthlyPay, setMonthlyPay] = useState(0);

  const [taxes, setTaxes] = useState(TAXES);

  const [holidayBonusText, setHolidayBonusText] = useState('');
  const [holidayBonus, setHolidayBonus] = useState(0);
  const [hourlyPayGross, setHourlyPayGross] = useState(0);
  const [grossSalary, setGrossSalary] = useState(0);

  const [hourlyDeduction, setHourlyDeduction] = useState(0);
  const [monthlyDeduction, setMonthlyDeduction] = useState(0);

  useEffect(() => {
    const fetchPayroll = async () => {
      try {
        const {
          data: { payroll },
        } = await authFetch.get(`payroll/${state?.payrollId}`);

        setMonthYear(moment(payroll?.monthYear)._d);
        setPlaceDate(payroll?.placeDate);
        setEmployer(payroll?.employer);
        setWorker(payroll?.worker);
        setCanton(payroll?.canton);
        setBillingProcedure(payroll?.billingProcedure);
        setTotalHours(payroll?.totalHours);
        setHourlyPay(payroll?.hourlyPay);
        setMonthlyPay(payroll?.monthlyPay);
        setTaxes(payroll?.taxes);
        setHolidayBonusText(TEXTTAXES[payroll?.holidayBonus]);
        setHolidayBonus(payroll?.holidayBonus);
        setHourlyPayGross(payroll?.hourlyPayGross);
        setGrossSalary(payroll?.grossSalary);
        setHourlyDeduction(payroll?.hourlyDeduction);
        setMonthlyDeduction(payroll?.monthlyDeduction);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    if (state?.payrollId !== undefined) fetchPayroll();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const {
          data: { users },
        } = await authFetch.get(`payroll/check?date=${moment(monthYear).format('YYYY-MM')}`);
        setUsers(users);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    if (monthYear !== null) fetchUsers();

    const fetchUser = async () => {
      try {
        const {
          data: { user, totalHours },
        } = await authFetch.get(`check-in-out/user/${userId}?total=true&date=${moment(monthYear).format('YYYY-MM')}`);

        setWorker({
          name: user?.lastName ? `${user?.lastName} ${user?.firstName}` : '',
          street: user?.street,
          plz: user?.postalCode,
          ahv: user?.ahv,
          email: user?.email,
        });

        setHourlyPay(user?.hourlyPay);

        const { hours, mins } = totalHours;
        if (totalHours === 0) {
          setTotalHours(totalHours);
        } else {
          const convertedTotalHours = hours + (mins / 60).toFixed(2);
          setTotalHours(convertedTotalHours);
        }
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    if (userId) fetchUser();
  }, [monthYear, userId]);

  useEffect(() => {
    let result = { ...taxes };
    if (canton === 'Wallis') {
      result = { ...result, FAK: 0.3 };
    } else {
      delete result.FAK;
    }
    setTaxes(result);
  }, [canton]);

  useEffect(() => {
    let result = { ...taxes };
    if (billingProcedure === 'Vereinfachtes') {
      result = { ...result, Quellensteur_: 5.0 };
    } else {
      delete result.Quellensteur_;
    }
    setTaxes(result);
  }, [billingProcedure]);

  useEffect(() => {
    if (billingProcedure === 'Monatslohn') {
      setHourlyPayGross(0);
      setGrossSalary(addTax(holidayBonus, monthlyPay));

      const aMonat = sum(
        Object.values(taxes).map((item) => ((item / 100) * addTax(holidayBonus, monthlyPay)).toFixed(2))
      );
      setHourlyDeduction(0);
      setMonthlyDeduction(aMonat);
    } else {
      setHourlyPayGross(addTax(holidayBonus, hourlyPay));
      setGrossSalary(addTax(holidayBonus, product([hourlyPay, totalHours])));

      const aStunde = sum(
        Object.values(taxes).map((item) => ((item / 100) * addTax(holidayBonus, hourlyPay)).toFixed(2))
      );
      const aMonat = sum(
        Object.values(taxes).map((item) =>
          ((item / 100) * addTax(holidayBonus, product([hourlyPay, totalHours]))).toFixed(2)
        )
      );
      setHourlyDeduction(aStunde);
      setMonthlyDeduction(aMonat);
    }
  }, [hourlyPay, totalHours, holidayBonus, taxes, billingProcedure, monthlyPay]);

  const arrayTaxes = Object.entries(taxes).map((item, i) => [
    { text: item[0], alignment: 'left' },
    { text: `${item[1]}%`, alignment: 'right' },
    { text: ((item[1] / 100) * hourlyPayGross).toFixed(2), alignment: 'right' },
    { text: ((item[1] / 100) * grossSalary).toFixed(2), alignment: 'right' },
  ]);
  arrayTaxes.unshift([
    { text: 'Abzüge', style: 'tableHeader', alignment: 'left' },
    { text: 'Sätze', style: 'tableHeader', alignment: 'right' },
    { text: 'Pro Stunde', style: 'tableHeader', alignment: 'right' },
    { text: 'Pro Monat', style: 'tableHeader', alignment: 'right' },
  ]);
  arrayTaxes.push([
    { text: 'Total Abzüge', style: 'tableHeader', alignment: 'left' },
    { text: `${sum(Object.values(taxes))}%`, alignment: 'right', style: 'cellFill' },
    { text: hourlyDeduction, alignment: 'right', style: 'cellFill' },
    { text: monthlyDeduction, alignment: 'right', style: 'cellFill' },
  ]);

  const savePayroll = async () => {
    try {
      const payroll = {
        userId,
        worker,
        employer,
        monthYear: moment(monthYear).format('YYYY-MM'),
        placeDate,
        canton,
        billingProcedure,
        totalHours,
        hourlyPay,
        monthlyPay,
        holidayBonus,
        hourlyPayGross,
        grossSalary,
        hourlyDeduction,
        monthlyDeduction,
        taxes,
      };
      const newPayroll = await authFetch.post('/payroll/', payroll);

      if (newPayroll?.data !== undefined) {
        setUsers([]);
        setUserId('');
        setMonthYear(null);
        setPlaceDate('');
        setEmployer(EMPLOYER);
        setWorker({ name: '', street: '', plz: '', ahv: '', email: '' });
        setCanton('Zürich');
        setBillingProcedure('Auswählen');
        setTotalHours(0);
        setHourlyPay(0);
        setMonthlyPay(0);
        setTaxes(TAXES);
        setHolidayBonusText('');
        setHolidayBonus(0);
        setHourlyPayGross(0);
        setGrossSalary(0);
        setHourlyDeduction(0);
        setMonthlyDeduction(0);
        sendPDF();
      }
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const sendPDF = async () => {
    try {
      const options =
        billingProcedure !== 'Monatslohn'
          ? pdfOptionsHourlyPay(
              monthYear,
              placeDate,
              employer,
              worker,
              canton,
              billingProcedure,
              hourlyPay,
              totalHours,
              holidayBonus,
              holidayBonusText,
              hourlyPayGross,
              grossSalary,
              arrayTaxes,
              hourlyDeduction,
              monthlyDeduction
            )
          : pdfOptionsMonthlyPay(
              monthYear,
              placeDate,
              employer,
              worker,
              canton,
              billingProcedure,
              monthlyPay,
              holidayBonus,
              holidayBonusText,
              hourlyPayGross,
              grossSalary,
              arrayTaxes,
              hourlyDeduction,
              monthlyDeduction
            );

      const pdfDocGenerator = pdfMake.createPdf(options);

      pdfDocGenerator.getBase64(async (document) => {
        const formData = new FormData();
        formData.append('name', worker.name);
        formData.append('email', worker.email);
        formData.append('pdfBase64', document);
        await authFetch.post(`user/send-email`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      });

      displayAlert('success', `${LOHNABRECHNUNG_EMAIL_SENT} ${worker.email}`);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const downloadPDF = async () => {
    try {
      const options =
        billingProcedure !== 'Monatslohn'
          ? pdfOptionsHourlyPay(
              monthYear,
              placeDate,
              employer,
              worker,
              canton,
              billingProcedure,
              hourlyPay,
              totalHours,
              holidayBonus,
              holidayBonusText,
              hourlyPayGross,
              grossSalary,
              arrayTaxes,
              hourlyDeduction,
              monthlyDeduction
            )
          : pdfOptionsMonthlyPay(
              monthYear,
              placeDate,
              employer,
              worker,
              canton,
              billingProcedure,
              monthlyPay,
              holidayBonus,
              holidayBonusText,
              hourlyPayGross,
              grossSalary,
              arrayTaxes,
              hourlyDeduction,
              monthlyDeduction
            );

      pdfMake.createPdf(options).download(`Payroll.pdf`);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  return (
    <>
      {showAlert && <Alert />}
      <Page title={PAYROLL}>
        <Container>
          <Card sx={{ mb: 2, p: 2 }}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <h2>{PAYROLL}</h2>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    views={['month', 'year']}
                    label={MONTH_YEAR}
                    minDate={moment('2016-01-01')._d}
                    maxDate={moment('2027-12-31')._d}
                    value={monthYear}
                    onChange={(newValue) => {
                      setMonthYear(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                    disabled={state?.payrollId !== undefined}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TextField
                  type="text"
                  label={PLACE_DATE}
                  value={placeDate}
                  onChange={(e) => setPlaceDate(e.target.value)}
                  disabled={state?.payrollId !== undefined}
                />
              </Grid>
            </Grid>
          </Card>
          {state?.payrollId === undefined && (
            <Card sx={{ mb: 2 }}>
              <CustomStyle>
                <FormControl fullWidth>
                  <InputLabel id="select-user-label">{USER}</InputLabel>
                  <Select
                    disabled={!monthYear}
                    labelId="select-user"
                    id="select-user"
                    value={userId}
                    label={USER}
                    onChange={(e) => setUserId(e.target.value)}
                  >
                    {users.map((user, i) => (
                      <MenuItem key={i} value={user._id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CustomStyle>
            </Card>
          )}

          <Card sx={{ mb: 2, p: 2 }}>
            <table id="employer" className="mainTable">
              <thead>
                <tr>
                  <th>{ARBEITGEBER}</th>
                  <th>{` `}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{`${LAST_NAME}, ${FIRST_NAME}`}</td>
                  <td>
                    <input
                      type="text"
                      id="employer-name"
                      name="employer-name"
                      value={employer?.name}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => setEmployer((prev) => ({ ...prev, name: e.target.value }))}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{STREET}</td>
                  <td>
                    <input
                      type="text"
                      id="employer-street"
                      name="employer-street"
                      value={employer?.street}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => setEmployer((prev) => ({ ...prev, street: e.target.value }))}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{`${POSTAL_CODE}, ${PLACE}`}</td>
                  <td>
                    <input
                      type="text"
                      id="employer-plz"
                      name="employer-plz"
                      value={employer?.plz}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => setEmployer((prev) => ({ ...prev, plz: e.target.value }))}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>

          <Card sx={{ mb: 2, p: 2 }}>
            <table id="worker" className="mainTable" border="1">
              <thead>
                <tr>
                  <th>{WORKER}</th>
                  <th>{` `}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{`${LAST_NAME}, ${FIRST_NAME}`}</td>
                  <td>
                    <input
                      type="text"
                      id="worker-name"
                      name="worker-name"
                      value={worker?.name}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => setWorker((prev) => ({ ...prev, name: e.target.value }))}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{STREET}</td>
                  <td>
                    <input
                      type="text"
                      id="worker-street"
                      name="worker-street"
                      value={worker?.street}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => setWorker((prev) => ({ ...prev, street: e.target.value }))}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{`${POSTAL_CODE}, ${PLACE}`}</td>
                  <td>
                    <input
                      type="text"
                      id="worker-plz"
                      name="worker-plz"
                      value={worker?.plz}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => setWorker((prev) => ({ ...prev, plz: e.target.value }))}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{AHV_NUMBER}</td>
                  <td>
                    <input
                      type="text"
                      id="worker-ahv"
                      name="worker-ahv"
                      value={worker?.ahv}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => setWorker((prev) => ({ ...prev, ahv: e.target.value }))}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
          <Card sx={{ mb: 2, p: 2 }}>
            <table id="grundlagen" className="mainTable" border="1">
              <thead>
                <tr>
                  <th>{BASICS}</th>
                  <th>{` `}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{KANTON}</td>
                  <td>
                    <select
                      size="1"
                      id="canton"
                      name="canton"
                      value={canton}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => setCanton(e.target.value)}
                    >
                      {Object.entries(CANTON).map((arr, i) => (
                        <option key={i} value={arr[0]} selected={arr[0] === 'Zürich' && 'selected'}>
                          {arr[0]}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>{BILLINGPROCEDUREG}</td>
                  <td>
                    <select
                      size="1"
                      id="billingProcedure"
                      name="billingProcedure"
                      value={billingProcedure}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => setBillingProcedure(e.target.value)}
                    >
                      {BILLINGPROCEDURES.map((item) => (
                        <option value={item} selected={item === 'Auswählen' && 'selected'}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                {billingProcedure !== 'Monatslohn' ? (
                  <>
                    <tr>
                      <td>{HOURLYPAY}</td>
                      <td>
                        <input
                          type="number"
                          id="stundenlohn"
                          name="stundenlohn"
                          value={hourlyPay}
                          disabled={state?.payrollId !== undefined}
                          onChange={(e) => setHourlyPay(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{TOTAL_HOURS}</td>
                      <td>
                        <input
                          type="number"
                          id="anzahl-stunden"
                          name="anzahl-stunden"
                          value={totalHours}
                          disabled={state?.payrollId !== undefined}
                          onChange={(e) => setTotalHours(e.target.value)}
                        />
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td>{MONATSLOHN}</td>
                    <td>
                      <input
                        type="number"
                        id="monthlyPay"
                        name="monthlyPay"
                        value={monthlyPay}
                        disabled={state?.payrollId !== undefined}
                        onChange={(e) => setMonthlyPay(e.target.value)}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card>
          <Card sx={{ mb: 2, p: 2 }}>
            <table id="lohn" className="mainTable" border="1">
              <thead>
                <tr>
                  <th>{LOHN}</th>
                  <th>{SATZE}</th>
                  <th>{PRO_STUNDE}</th>
                  <th>{PRO_MONAT}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{ARBEITSLOHN}</td>
                  <td>{` `}</td>
                  <td>{billingProcedure === 'Monatslohn' ? 0 : hourlyPay}</td>
                  <td>{billingProcedure === 'Monatslohn' ? monthlyPay : product([hourlyPay, totalHours])} </td>
                </tr>
                <tr>
                  <td>{HOLIDAYBONUSG}</td>

                  <td>
                    <select
                      size="1"
                      id="holidayBonus"
                      name="holidayBonus"
                      value={holidayBonus}
                      disabled={state?.payrollId !== undefined}
                      onChange={(e) => {
                        setHolidayBonus(e.target.value);
                        setHolidayBonusText(TEXTTAXES[e.target.value]);
                      }}
                    >
                      {Object.entries(HOLIDAYBONUS).map((arr, i) => (
                        <option key={i} value={arr[1]} selected={arr[0] === 'Auswählen' && 'selected'}>
                          {arr[0]}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>{billingProcedure === 'Monatslohn' ? 0 : product([holidayBonus, hourlyPay])}</td>
                  <td>
                    {billingProcedure === 'Monatslohn'
                      ? product([holidayBonus, monthlyPay])
                      : product([holidayBonus, hourlyPay, totalHours])}
                  </td>
                  <td>{}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>{BRUTTOLOHN}</th>
                  <td>{` `}</td>
                  <td>{hourlyPayGross}</td>
                  <td>{grossSalary}</td>
                </tr>
              </tfoot>
            </table>
          </Card>
          <Card sx={{ mb: 2, p: 2 }}>
            <table id="abzuge" className="mainTable" border="1">
              <thead>
                <tr>
                  <th>{ABZUGE}</th>
                  <th>{`${SATZE} (%)`}</th>
                  <th>{PRO_STUNDE}</th>
                  <th>{PRO_MONAT}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(taxes).map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item[0]}</td>
                      {/* <td>{item[1]}%</td> */}
                      <td>
                        {' '}
                        <input
                          type="number"
                          id={`tax-${i}`}
                          className={`tax`}
                          name={`tax-${i}`}
                          value={item[1]}
                          disabled={state?.payrollId !== undefined}
                          onChange={(e) => {
                            const result = { ...taxes };
                            Object.keys(result).map((x) => {
                              if (x === item[0]) result[x] = +e.target.value;
                              return x;
                            });
                            setTaxes(result);
                          }}
                        />
                      </td>

                      <td>{((item[1] / 100) * hourlyPayGross).toFixed(2)}</td>
                      <td>{((item[1] / 100) * grossSalary).toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>{TOTAL_ABZUGE}</th>
                  <td>{sum(Object.values(taxes))}%</td>
                  <td>{hourlyDeduction}</td>
                  <td>{monthlyDeduction}</td>
                </tr>
              </tfoot>
            </table>
          </Card>
          <Card sx={{ mb: 2, p: 2 }}>
            <table id="nettolohn" className="mainTable" border="1">
              <tfoot>
                <tr>
                  <th>{NETTOLOHN}</th>
                  <td>{` `}</td>
                  <td>{(hourlyPayGross - hourlyDeduction).toFixed(2)}</td>
                  <td>{(grossSalary - monthlyDeduction).toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          </Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Button
              sx={{ marginTop: '10px' }}
              variant="contained"
              onClick={() => {
                downloadPDF();
              }}
            >
              {PDF}
            </Button>
            {state?.payrollId === undefined && (
              <Button
                sx={{ marginTop: '10px' }}
                variant="contained"
                onClick={() => {
                  savePayroll();
                }}
              >
                {SAVE_SEND_EMAIL}
              </Button>
            )}
          </Stack>
        </Container>
      </Page>
    </>
  );
};
export default Payroll;
