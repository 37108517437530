import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  ToggleButton,
} from '@mui/material';
import './styleAllPages.css';

import { Alert, AlertDialogText, Page, Scrollbar, Iconify, SearchNotFound, AlertDialog } from '../components';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import { useAppContext } from '../context/app-context';
import {
  FIRST_NAME,
  LAST_NAME,
  LOCATION,
  EMAIL,
  PHONE,
  ROLE,
  HOURLY_PAY,
  USER,
  USERS,
  NEW_USER,
  ARE_SURE_RESTORE_USER,
  ACTIVE,
  INACTIVE,
  TRASH,
} from '../lang/en';
import { getComparator, applySortFilter } from '../utils/sortDataTable';

const TABLE_HEAD = [
  { id: 'firstName', label: `${FIRST_NAME}`, alignRight: false },
  { id: 'lastName', label: `${LAST_NAME}`, alignRight: false },
  { id: 'location', label: `${LOCATION}`, alignRight: false },
  { id: 'email', label: `${EMAIL}`, alignRight: false },
  { id: 'phone', label: `${PHONE}`, alignRight: false },
  { id: 'role', label: `${ROLE}`, alignRight: false },
  { id: 'hourlyPay', label: `${HOURLY_PAY}`, alignRight: false },
  { id: '' },
];

export default function Usera() {
  const { showAlert, displayAlert, authFetch } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [usersStatus, setUsersStatus] = useState(true);
  const [usersTrash, setUsersTrash] = useState(false);

  const [userId, setUserId] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isRestoreOpen, setIsRestoreOpen] = useState(false);
  const [hasDeletedRestored, setHasDeletedRestored] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await authFetch.get(
          `user?page=${page + 1}&search=${filterName}&active=${usersStatus}&deleted=${usersTrash && 'true'}`
        );
        const { users, totalUsers } = data;
        setUsers(users);
        setTotalUsers(totalUsers);
        setHasDeletedRestored(false);
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchUsers();
  }, [page, filterName, usersStatus, usersTrash, hasDeletedRestored]);

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy));

  const isUserNotFound = filteredUsers.length === 0;

  const handleDelete = (deleteId) => {
    setIsDeleteOpen(true);
    setUserId(deleteId);
  };
  const handleClose = () => {
    setIsDeleteOpen(false);
  };
  const handleConfirmDelete = async () => {
    try {
      const permanently = usersTrash ? 'permanently' : '';
      const userDelete = await authFetch.delete(`user/${userId}/${permanently}`);

      setHasDeletedRestored(true);
      setIsDeleteOpen(false);

      displayAlert('success', userDelete?.data?.msg);
    } catch (error) {
      setIsDeleteOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const handleRestore = (restoreId) => {
    setIsRestoreOpen(true);
    setUserId(restoreId);
  };
  const handleCloseRestore = () => {
    setIsRestoreOpen(false);
  };
  const handleConfirmRestore = async () => {
    try {
      const userRestore = await authFetch.patch(`user/${userId}/restore`);

      setHasDeletedRestored(true);
      setIsRestoreOpen(false);

      displayAlert('success', userRestore?.data?.msg);
    } catch (error) {
      setIsRestoreOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  return (
    <>
      {showAlert && <Alert />}
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
          {showAlert && <Alert />}
        </>
      ) : (
        <>
          <Page title={USER}>
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  {USERS}
                </Typography>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/dashboard/new-user"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {NEW_USER}
                </Button>
              </Stack>

              <Card>
                <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} position="leftPosition" />

                <Button
                  className={`filter__Button ${!usersTrash && usersStatus && 'active'}`}
                  variant="outlined"
                  onClick={() => {
                    setUsersStatus(true);
                    setUsersTrash(false);
                  }}
                >
                  {ACTIVE}
                </Button>

                <Button
                  className={`filter__Button ${!usersTrash && !usersStatus && 'active'}`}
                  variant="outlined"
                  onClick={() => {
                    setUsersStatus(false);
                    setUsersTrash(false);
                  }}
                >
                  {INACTIVE}
                </Button>

                <Button
                  className={`filter__Button ${usersTrash && 'deleted'}`}
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setUsersTrash(true);
                  }}
                >
                  {TRASH}
                </Button>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {filteredUsers.map((user) => {
                          const {
                            _id,
                            firstName,
                            lastName,
                            email,
                            phone,
                            role,
                            hourlyPay,
                            locationId: { locationName },
                          } = user;

                          return (
                            <TableRow hover key={_id} tabIndex={-1}>
                              <TableCell align="left">{firstName}</TableCell>
                              <TableCell align="left">{lastName}</TableCell>
                              <TableCell align="left">{locationName}</TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">{phone}</TableCell>
                              <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                                {role}
                              </TableCell>
                              <TableCell align="left">{hourlyPay}</TableCell>
                              <TableCell align="right">
                                <UserMoreMenu
                                  id={_id}
                                  handleDelete={handleDelete}
                                  trash={usersTrash}
                                  handleRestore={handleRestore}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>

                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={totalUsers}
                  rowsPerPage={10}
                  page={page}
                  onPageChange={handleChangePage}
                  showFirstButton
                  showLastButton
                />
              </Card>

              {isDeleteOpen && (
                <AlertDialog handleClose={handleClose} handleConfirmDelete={handleConfirmDelete} text={USER} />
              )}
              {isRestoreOpen && (
                <AlertDialogText
                  handleClose={handleCloseRestore}
                  handleConfirm={handleConfirmRestore}
                  text={ARE_SURE_RESTORE_USER}
                />
              )}
            </Container>
          </Page>
        </>
      )}
    </>
  );
}
