import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Box,
  CircularProgress,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';

import { Page, Scrollbar, Iconify, AlertDialog, AlertDialogText, Alert } from '../components';
import { CarTransferMoreMenu } from '../sections/@dashboard/carTransfer';
import { useAppContext } from '../context/app-context';
import {
  CARS_TRANSFERED,
  CAR_TRANSFER,
  NEW_CAR_TRANSFER,
  USER,
  SEARCH_PLATE,
  VALID_CAR_TRANSFER,
  NO_RESULTS_FOUND,
  FROM_DATE,
  TO_DATE,
  RESET,
  EXCEL,
  DATE,
  LICENSE_PLATE,
  CAR_TYPE,
  TRANSFER_METHOD,
  TRANSFER_TYPE,
  PRICE,
  LOCATION,
  FINAL_PRICE,
  TOTAL,
} from '../lang/en';

const SearchStyle = styled(OutlinedInput)(() => ({
  width: '100%',
}));

const TABLE_HEAD = [
  { id: 'user', label: `${USER}`, alignRight: false },
  { id: 'createdDate', label: `${DATE}`, alignRight: false },
  { id: 'licensePlate', label: `${LICENSE_PLATE}`, alignRight: false },
  { id: 'carType', label: `${CAR_TYPE}`, alignRight: false },
  { id: 'transferMethod', label: `${TRANSFER_METHOD}`, alignRight: false },
  { id: 'transferType', label: `${TRANSFER_TYPE}`, alignRight: false },
  { id: 'price', label: `${PRICE}`, alignRight: false },
];

const CarsTransfered = () => {
  const { state } = useLocation();
  const [baseUrl, setBaseUrl] = useState([]);

  const { showAlert, displayAlert, authFetch, user } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [carsTransfer, setCarsTransfer] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCarsTransfer, setTotalCarsTransfer] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const [users, setUsers] = useState([]);
  const [filterPlate, setFilterPlate] = useState('');
  const [userId, setUserId] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [carTransferId, setCarTransferId] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);
  const [isSuspectOpen, setIsSuspectOpen] = useState(false);
  const [hasSuspected, setHasSuspected] = useState(false);

  useEffect(() => {
    if (user?.role === 'admin') {
      setBaseUrl('dashboard');
    }
    if (user?.role === 'manager') {
      setBaseUrl('manager');
    }

    const fetchUsers = async () => {
      try {
        const { data } = await authFetch.get(`user?limit=1000`);
        const { users } = data;
        setUsers(users);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchCarsTransfer = async () => {
      try {
        const fDate = fromDate === null ? '' : fromDate;
        const tDate = toDate === null ? '' : toDate;
        const { data } = await authFetch.get(
          `car-transfer/admin/${state?.locationId}/?search=${filterPlate}&page=${
            page + 1
          }&userId=${userId}&from=${fDate}&to=${tDate}`
        );
        const { carTransferred, totalCarTransferred, totalPrice } = data;

        setCarsTransfer(carTransferred);
        setTotalCarsTransfer(totalCarTransferred);
        setTotalPrice(totalPrice);
        setHasDeleted(false);
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchCarsTransfer();
  }, [state, page, filterPlate, userId, fromDate, toDate, hasDeleted, hasSuspected]);

  const handleFilterByPlate = (event) => {
    setFilterPlate(event.target.value);
  };

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };
  const handleReset = () => {
    setUserId('');
    setFromDate(null);
    setToDate(null);
    setFilterPlate('');
  };

  const handleDelete = (deleteId) => {
    setIsDeleteOpen(true);
    setCarTransferId(deleteId);
  };
  const handleClose = () => {
    setIsDeleteOpen(false);
  };
  const handleConfirmDelete = async () => {
    try {
      const carTransferDelete = await authFetch.delete(`car-transfer/${carTransferId}`);
      setIsDeleteOpen(false);
      setHasDeleted(true);
      displayAlert('success', carTransferDelete?.data?.msg);
    } catch (error) {
      setIsDeleteOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const handleSuspect = (suspectId) => {
    setIsSuspectOpen(true);
    setCarTransferId(suspectId);
  };
  const handleCloseSuspect = () => {
    setIsSuspectOpen(false);
  };
  const handleConfirmSuspect = async () => {
    try {
      const carTransferSuspected = await authFetch.patch(`car-transfer/${carTransferId}/suspect`);
      setIsSuspectOpen(false);
      setHasSuspected(true);
      displayAlert('success', carTransferSuspected?.data?.msg);
    } catch (error) {
      setIsSuspectOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const emptyCarsTransfer = carsTransfer.length === 0;

  const fetchCarsWashExcel = async () => {
    const fDate = fromDate === null ? '' : fromDate;
    const tDate = toDate === null ? '' : toDate;
    const { data } = await authFetch.get(
      `car-transfer/get-excel?search=${filterPlate}&locationId=${state?.locationId}&userId=${userId}&from=${fDate}&to=${tDate}`
    );
    return data;
  };

  const downloadExcel = async () => {
    const data = await fetchCarsWashExcel();
    const excelData = data?.data.map((item) => {
      item.date = moment(item.date).format('DD MMMM, HH:mm');
      return item;
    });

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [[USER, DATE, LICENSE_PLATE, CAR_TYPE, TRANSFER_METHOD, TRANSFER_TYPE, FINAL_PRICE]],
      {
        origin: 'A1',
      }
    );

    XLSX.utils.sheet_add_aoa(worksheet, [], { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, [[`${TOTAL}`, '', '', '', '', '', data.totalPrice]], {
      origin: -1,
    });

    XLSX.writeFile(workbook, `${CARS_TRANSFERED}, ${state?.locationName}.xlsx`);
  };

  return (
    <>
      {showAlert && <Alert />}
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <Page title={CARS_TRANSFERED}>
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {CARS_TRANSFERED} - {state?.locationName}
              </Typography>
              <Button
                variant="contained"
                component={RouterLink}
                to={`/${baseUrl}/new-car-transfer`}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                {NEW_CAR_TRANSFER}
              </Button>
            </Stack>
            <Card>
              <div className="filter__control">
                <SearchStyle
                  value={filterPlate}
                  onChange={handleFilterByPlate}
                  placeholder={SEARCH_PLATE}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </div>
              <div className="filter__control">
                <FormControl fullWidth>
                  <InputLabel id="select-user-label">{USER}</InputLabel>
                  <Select
                    labelId="select-user"
                    id="select-user"
                    value={userId}
                    label={USER}
                    onChange={(e) => setUserId(e.target.value)}
                    MenuProps={{ PaperProps: { sx: { maxHeight: '50vh' } } }}
                  >
                    {users.map((user, i) => (
                      <MenuItem key={i} value={user._id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="filter__control">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={FROM_DATE}
                    value={fromDate}
                    onChange={(newValue) => {
                      setFromDate(moment(newValue).format('YYYY-MM-DD'));
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'dd/MM/yyyy',
                          }}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="filter__control">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={TO_DATE}
                    value={toDate}
                    onChange={(newValue) => {
                      setToDate(moment(newValue).format('YYYY-MM-DD'));
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'dd/MM/yyyy',
                          }}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="filter__button">
                <Button sx={{ lineHeight: '2.8' }} variant="outlined" onClick={handleReset}>
                  {RESET}
                </Button>
              </div>
              <div className="filter__button">
                <Button sx={{ lineHeight: '2.8' }} variant="outlined" onClick={downloadExcel}>
                  {EXCEL}
                </Button>
              </div>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {carsTransfer.map((carTransfer) => {
                        const {
                          _id,
                          user,
                          date,
                          licensePlate,
                          carType,
                          transferMethod,
                          transferType,
                          finalPrice,
                          suspect,
                        } = carTransfer;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            style={suspect ? { backgroundColor: '#ff000080' } : {}}
                          >
                            <TableCell align="left">{user}</TableCell>
                            <TableCell align="left">{moment(date).format('DD MMMM, HH:mm')}</TableCell>
                            <TableCell align="left">{licensePlate}</TableCell>
                            <TableCell align="left">{carType}</TableCell>
                            <TableCell align="left" style={{ textTransform: 'capitalize' }}>
                              {transferMethod}
                            </TableCell>
                            <TableCell align="left">{transferType}</TableCell>
                            <TableCell align="left">{finalPrice}</TableCell>

                            <TableCell align="right">
                              <CarTransferMoreMenu
                                id={_id}
                                handleDelete={handleDelete}
                                suspect={suspect}
                                handleSuspect={handleSuspect}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {!emptyCarsTransfer && (
                        <TableRow hover tabIndex={-1}>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {TOTAL}
                          </TableCell>
                          <TableCell align="left" />
                          <TableCell align="left" />
                          <TableCell align="left" />
                          <TableCell align="left" />
                          <TableCell align="left" />
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {totalPrice}
                          </TableCell>
                          <TableCell align="left" />
                        </TableRow>
                      )}
                    </TableBody>

                    {emptyCarsTransfer && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Typography variant="body2" align="center">
                              {NO_RESULTS_FOUND}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={totalCarsTransfer}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Card>
            {isDeleteOpen && (
              <AlertDialog handleClose={handleClose} handleConfirmDelete={handleConfirmDelete} text={CAR_TRANSFER} />
            )}
            {isSuspectOpen && (
              <AlertDialogText
                handleClose={handleCloseSuspect}
                handleConfirm={handleConfirmSuspect}
                text={VALID_CAR_TRANSFER}
              />
            )}
          </Container>
        </Page>
      )}
    </>
  );
};
export default CarsTransfered;
