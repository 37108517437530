import moment from 'moment';

const product = (arr) => {
  const newArr = arr.map(Number);
  const product = newArr.reduce((partialProduct, i) => partialProduct * i, 1);
  return product.toFixed(2);
};

export const pdfOptionsHourlyPay = (
  monthYear,
  placeDate,
  employer,
  worker,
  canton,
  billingProcedure,
  hourlyPay,
  totalHours,
  holidayBonus,
  holidayBonusText,
  hourlyPayGross,
  grossSalary,
  arrayTaxes,
  hourlyDeduction,
  monthlyDeduction
) => {
  return {
    info: {
      title: 'Payroll',
    },
    pageSize: 'A4',
    pageOrientation: 'portrait',
    styles: {
      header: {
        fontSize: 15,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      subheader: {
        fontSize: 13,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 5],
      },
      tableHeader: {
        bold: true,
        fontSize: 11,
        color: 'black',
        fillColor: '#e8eef7',
      },
      cellFill: {
        fillColor: '#e8eef7',
      },
    },
    content: [
      {
        layout: 'noBorders',
        style: 'tableExample',
        table: {
          widths: ['25%', '25%', '50%'],
          body: [
            [
              { text: 'Lohnabrechnung', style: 'header', alignment: 'left' },
              { text: moment(monthYear).format('MMMM, YYYY'), style: 'subheader', alignment: 'left' },
              { text: placeDate, style: 'subheader', alignment: 'right' },
            ],
          ],
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '75%'],
          headerRows: 1,
          body: [
            [
              { text: 'Arbeitgeber', style: 'tableHeader', alignment: 'left' },
              { text: ' ', style: 'tableHeader' },
            ],
            ['Vorname, Name', employer.name],
            ['Strasse', employer.street],
            ['PLZ, Wohnort', employer.plz],
          ],
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '75%'],
          headerRows: 1,
          body: [
            [
              { text: 'Arbeitnehmer', style: 'tableHeader', alignment: 'left' },
              { text: ' ', style: 'tableHeader' },
            ],
            ['Vorname, Name', worker.name],
            ['Strasse', worker.street],
            ['PLZ, Wohnort', worker.plz],
            ['AHV-Nummer', worker.ahv],
          ],
        },
      },

      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '75%'],
          headerRows: 1,
          body: [
            [
              { text: 'Grundlagen', style: 'tableHeader', alignment: 'left' },
              { text: ' ', style: 'tableHeader' },
            ],
            ['Kanton', canton],
            ['Abrechnungsverfahren', billingProcedure],
            ['Stundenlohn', hourlyPay],
            ['Anzahl Stunden', totalHours],
          ],
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          headerRows: 1,
          body: [
            [
              { text: 'Lohn', style: 'tableHeader', alignment: 'left' },
              { text: 'Sätze', style: 'tableHeader', alignment: 'right' },
              { text: 'Pro Stunde', style: 'tableHeader', alignment: 'right' },
              { text: 'Pro Monat', style: 'tableHeader', alignment: 'right' },
            ],
            [
              { text: 'Arbeitslohn', alignment: 'left' },
              {},
              { text: hourlyPay, alignment: 'right' },
              { text: product([hourlyPay, totalHours]), alignment: 'right' },
            ],
            [
              { text: 'Ferienzuschlag', alignment: 'left' },
              { text: holidayBonusText, alignment: 'right' },
              { text: product([holidayBonus, hourlyPay]), alignment: 'right' },
              { text: product([holidayBonus, hourlyPay, totalHours]), alignment: 'right' },
            ],
            [
              { text: 'Bruttolohn', style: 'tableHeader', alignment: 'left' },
              { text: ' ', style: 'tableHeader' },
              { text: hourlyPayGross, alignment: 'right', style: 'cellFill' },
              { text: grossSalary, alignment: 'right', style: 'cellFill' },
            ],
          ],
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          headerRows: 1,
          body: arrayTaxes,
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          headerRows: 1,
          body: [
            [
              { text: 'Nettolohn', style: 'tableHeader', alignment: 'left', fillColor: '#e8eef7' },
              { text: ' ', fillColor: '#e8eef7' },
              { text: (hourlyPayGross - hourlyDeduction).toFixed(2), alignment: 'right', fillColor: '#e8eef7' },
              { text: (grossSalary - monthlyDeduction).toFixed(2), alignment: 'right', fillColor: '#e8eef7' },
            ],
          ],
        },
      },
    ],
  };
};

export const pdfOptionsMonthlyPay = (
  monthYear,
  placeDate,
  employer,
  worker,
  canton,
  billingProcedure,
  monthlyPay,
  holidayBonus,
  holidayBonusText,
  hourlyPayGross,
  grossSalary,
  arrayTaxes,
  hourlyDeduction,
  monthlyDeduction
) => {
  return {
    info: {
      title: 'Payroll',
    },
    pageSize: 'A4',
    pageOrientation: 'portrait',
    styles: {
      header: {
        fontSize: 15,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      subheader: {
        fontSize: 13,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 5],
      },
      tableHeader: {
        bold: true,
        fontSize: 11,
        color: 'black',
        fillColor: '#e8eef7',
      },
      cellFill: {
        fillColor: '#e8eef7',
      },
    },
    content: [
      {
        layout: 'noBorders',
        style: 'tableExample',
        table: {
          widths: ['25%', '25%', '50%'],
          body: [
            [
              { text: 'Lohnabrechnung', style: 'header', alignment: 'left' },
              { text: moment(monthYear).format('MMMM, YYYY'), style: 'subheader', alignment: 'left' },
              { text: placeDate, style: 'subheader', alignment: 'right' },
            ],
          ],
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '75%'],
          headerRows: 1,
          body: [
            [
              { text: 'Arbeitgeber', style: 'tableHeader', alignment: 'left' },
              { text: ' ', style: 'tableHeader' },
            ],
            ['Vorname, Name', employer.name],
            ['Strasse', employer.street],
            ['PLZ, Wohnort', employer.plz],
          ],
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '75%'],
          headerRows: 1,
          body: [
            [
              { text: 'Arbeitnehmer', style: 'tableHeader', alignment: 'left' },
              { text: ' ', style: 'tableHeader' },
            ],
            ['Vorname, Name', worker.name],
            ['Strasse', worker.street],
            ['PLZ, Wohnort', worker.plz],
            ['AHV-Nummer', worker.ahv],
          ],
        },
      },

      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '75%'],
          headerRows: 1,
          body: [
            [
              { text: 'Grundlagen', style: 'tableHeader', alignment: 'left' },
              { text: ' ', style: 'tableHeader' },
            ],
            ['Kanton', canton],
            ['Abrechnungsverfahren', billingProcedure],
            ['Monatlohn', monthlyPay],
          ],
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          headerRows: 1,
          body: [
            [
              { text: 'Lohn', style: 'tableHeader', alignment: 'left' },
              { text: 'Sätze', style: 'tableHeader', alignment: 'right' },
              { text: 'Pro Stunde', style: 'tableHeader', alignment: 'right' },
              { text: 'Pro Monat', style: 'tableHeader', alignment: 'right' },
            ],
            [
              { text: 'Arbeitslohn', alignment: 'left' },
              {},
              { text: 0, alignment: 'right' },
              { text: monthlyPay, alignment: 'right' },
            ],
            [
              { text: 'Ferienzuschlag', alignment: 'left' },
              { text: holidayBonusText, alignment: 'right' },
              { text: 0, alignment: 'right' },
              { text: product([holidayBonus, monthlyPay]), alignment: 'right' },
            ],
            [
              { text: 'Bruttolohn', style: 'tableHeader', alignment: 'left' },
              { text: ' ', style: 'tableHeader' },
              { text: hourlyPayGross, alignment: 'right', style: 'cellFill' },
              { text: grossSalary, alignment: 'right', style: 'cellFill' },
            ],
          ],
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          headerRows: 1,
          body: arrayTaxes,
        },
      },
      {
        layout: 'lightHorizontalLines',
        style: 'tableExample',
        fontSize: 10,
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          headerRows: 1,
          body: [
            [
              { text: 'Nettolohn', style: 'tableHeader', alignment: 'left', fillColor: '#e8eef7' },
              { text: ' ', fillColor: '#e8eef7' },
              { text: (hourlyPayGross - hourlyDeduction).toFixed(2), alignment: 'right', fillColor: '#e8eef7' },
              { text: (grossSalary - monthlyDeduction).toFixed(2), alignment: 'right', fillColor: '#e8eef7' },
            ],
          ],
        },
      },
    ],
  };
};
