import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Card, Stack, Container, Typography } from '@mui/material';

import { NewLocationForm, EditLocationForm } from '../sections/@dashboard/locations';
import { Page } from '../components';
import { LOCATION, UPDATE_LOCATION, ADD_LOCATION } from '../lang/en';

const NewLocation = () => {
  const { state } = useLocation();

  return (
    <Page title={LOCATION}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {state?.locationId ? UPDATE_LOCATION : ADD_LOCATION}
          </Typography>
        </Stack>
        <Card style={{ padding: '20px' }}>
          {state?.locationId ? <EditLocationForm locationId={state?.locationId} /> : <NewLocationForm />}
        </Card>
      </Container>
    </Page>
  );
};
export default NewLocation;
