import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  CircularProgress,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
  Toolbar,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { Alert, Page, Scrollbar, Iconify, AlertDialog } from '../components';
import PayrollsMoreMenu from '../sections/@dashboard/payroll/PayrollsMoreMenu';
import { useAppContext } from '../context/app-context';
import {
  USER,
  DATE,
  FROM_DATE,
  TO_DATE,
  RESET,
  NO_RESULTS_FOUND,
  TOTAL_HOURS,
  HOURLYPAY,
  NETTOLOHN,
  PAYROLLS,
  NEW_PAYROLL,
} from '../lang/en';

const TABLE_HEAD = [
  { id: 'userId', label: `${USER}`, alignRight: false },
  { id: 'monthYear', label: `${DATE}`, alignRight: false },
  { id: 'totalHours', label: `${TOTAL_HOURS}`, alignRight: false },
  { id: 'hourlyPay', label: `${HOURLYPAY}`, alignRight: false },
  { id: 'netoSalary', label: `${NETTOLOHN}`, alignRight: false },
  { id: 'actions' },
];

const Payrolls = () => {
  const { showAlert, displayAlert, authFetch, user } = useAppContext();
  const [baseUrl, setBaseUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [payrolls, setPayrolls] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPayrolls, setTotalPayrolls] = useState(0);

  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [payrollId, setPayrollId] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);

  useEffect(() => {
    if (user?.role === 'admin') {
      setBaseUrl('dashboard');
    }
    if (user?.role === 'accountant') {
      setBaseUrl('accountant');
    }
    const fetchUsers = async () => {
      try {
        const { data } = await authFetch.get(`user?limit=1000`);
        const { users } = data;
        setUsers(users);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchUsers();
  }, []);

  const fetchPayrolls = async () => {
    try {
      const fDate = fromDate === null ? '' : fromDate;
      const tDate = toDate === null ? '' : toDate;
      const { data } = await authFetch.get(`payroll/?page=${page + 1}&userId=${userId}&from=${fDate}&to=${tDate}`);
      const { payrolls, totalPayrolls } = data;

      setPayrolls(payrolls);
      setTotalPayrolls(totalPayrolls);
      setHasDeleted(false);
      setIsLoading(false);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };
  useEffect(() => {
    fetchPayrolls();
  }, [page, userId, fromDate, toDate, hasDeleted]);

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };
  const handleReset = () => {
    setUserId('');
    setFromDate(null);
    setToDate(null);
  };

  const handleDelete = (deleteId) => {
    setIsDeleteOpen(true);
    setPayrollId(deleteId);
  };
  const handleClose = () => {
    setIsDeleteOpen(false);
  };
  const handleConfirmDelete = async () => {
    try {
      const payrollDelete = await authFetch.delete(`payroll/${payrollId}`);
      setIsDeleteOpen(false);
      setHasDeleted(true);
      displayAlert('success', payrollDelete?.data?.msg);
    } catch (error) {
      setIsDeleteOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const emptyPayrolls = payrolls.length === 0;

  return (
    <>
      {showAlert && <Alert />}
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title={PAYROLLS}>
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  {PAYROLLS}
                </Typography>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`/${baseUrl}/payroll`}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {NEW_PAYROLL}
                </Button>
              </Stack>

              <Card>
                <div className="filter__control">
                  <FormControl fullWidth>
                    <InputLabel id="select-user-label">{USER}</InputLabel>
                    <Select
                      labelId="select-user"
                      id="select-user"
                      value={userId}
                      label={USER}
                      onChange={(e) => setUserId(e.target.value)}
                      MenuProps={{ PaperProps: { sx: { maxHeight: '50vh' } } }}
                    >
                      {users.map((user, i) => (
                        <MenuItem key={i} value={user._id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="filter__control">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={FROM_DATE}
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(moment(newValue).format('YYYY-MM-DD'));
                      }}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'dd/MM/yyyy',
                            }}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="filter__control">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={TO_DATE}
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(moment(newValue).format('YYYY-MM-DD'));
                      }}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'dd/MM/yyyy',
                            }}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className="filter__button">
                  <Button sx={{ lineHeight: '2.8' }} variant="outlined" onClick={handleReset}>
                    {RESET}
                  </Button>
                </div>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {TABLE_HEAD.map((headCell) => (
                            <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payrolls.map((payroll) => {
                          const { _id, worker, monthYear, totalHours, hourlyPay, grossSalary, monthlyDeduction } =
                            payroll;

                          return (
                            <TableRow hover key={_id} tabIndex={-1}>
                              <TableCell align="left">{worker?.name}</TableCell>
                              <TableCell align="left">{monthYear}</TableCell>
                              <TableCell align="left">{totalHours}</TableCell>
                              <TableCell align="left">{hourlyPay}</TableCell>
                              <TableCell align="left">{(grossSalary - monthlyDeduction).toFixed(2)}</TableCell>
                              <TableCell align="left">.</TableCell>
                              <TableCell align="right">
                                <PayrollsMoreMenu id={_id} handleDelete={handleDelete} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>

                      {emptyPayrolls && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Typography variant="body2" align="center">
                                {NO_RESULTS_FOUND}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={totalPayrolls}
                  rowsPerPage={10}
                  page={page}
                  onPageChange={handleChangePage}
                  showFirstButton
                  showLastButton
                />
              </Card>
              {isDeleteOpen && (
                <AlertDialog handleClose={handleClose} handleConfirmDelete={handleConfirmDelete} text="check in out" />
              )}
            </Container>
          </Page>
        </>
      )}
    </>
  );
};
export default Payrolls;
