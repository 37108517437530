export const LOGIN_TITLE1 = 'Sign in to RHR Car Care';
export const LOGIN_TITLE2 = 'Enter your details below';
export const LOGIN_ALERT_SUCCESS = 'Login successful!';
export const LOGIN_ALERT_DANGER = 'Invalid Credentials';
export const LOGIN_FORGET_PASSWORD = 'Forgot password ?';
export const LOGIN_BUTTON = 'Login';

export const EMAIL = 'Email';
export const EMAIL_ADDRESS = 'Email address';
export const EMAIL_IS_REQUIRED = 'Email is required';
export const EMAIL_VALID = 'Email must be a valid email address';
export const PASSWORD = 'Password';
export const CURRENT_PASSWORD = 'Current Password';
export const NEW_PASSWORD = 'New Password';
export const CONFRIM_NEW_PASSWORD = 'Confirm New Password';
export const PASSWORD_IS_REQUIRED = 'Password is required';
export const NEW_PASSWORD_IS_REQUIRED = 'New Password is required';
export const NEW_AND_CONFIRM_PASSWORDS = 'New and Confirmed Password must be the same';

export const FIRST_NAME = 'Name';
export const LAST_NAME = 'Vorname';
export const FIRST_NAME_IS_REQUIRED = 'First Name is required';
export const LAST_NAME_IS_REQUIRED = 'Last Name is required';
export const LOCATION = 'Location';
export const LOCATION_NAME = 'Location Name';
export const SELECT_LOCATION_TYPE = 'Select Location Type';
export const ALL_LOCATIONS = 'All Locations';
export const NEW_LOCATION = 'New Location';
export const ADD_LOCATION = 'Add Location';
export const UPDATE_LOCATION = 'Update Location';
export const LOCATION_IS_REQUIRED = 'Location is required';
export const LOCATION_TYPE_IS_REQUIRED = 'Location Type is required';
export const PHONE = 'Phone';
export const PHONE_NUMBER = 'Phone number';
export const PHONE_VALID = 'Phone number is not valid';
export const ROLE = 'Role';
export const ROLE_IS_REQUIRED = 'Role is required';
export const STATUS = 'Status';
export const STATUS_IS_REQUIRED = 'Status is required';
export const SELECT_STATUS = 'Select status';
export const TOO_SHORT = 'Too Short!';
export const TOO_LONG = 'Too Long!';
export const TYPE = 'Type';
export const CAR_TYPE = 'Car Type';
export const CAR_TYPES = 'Car Types';
export const CAR_TYPE_IS_REQUIRED = 'Car Type is required';
export const ADD_NEW_CAR_TYPE = 'Add New Car Type';
export const MUST_BE_NUMBER = 'Must be a number';
export const TYPE_PRICE = 'Type price';
export const WITH_TRANSFER = 'With Transfer';
export const WITHOUT_TRANSFER = 'Without Transfer';
export const OUTSIDE_PRICE = 'Outside Price';
export const INSIDE_PRICE = 'Inside Price';
export const OUT_INSIDE_PRICE = 'Out and Inside Price';
export const MOTORRAD_PRICE = 'Motorrad Price';
export const TURNAROUND_PRICE = 'Turnaround Price';
export const QUICK_TURNAROUND_PRICE = 'Quick Turnaround Price';
export const HZP_PRICE = 'HZP Price';
export const HBP_PRICE = 'HBP Price';
export const APDT_PRICE = 'AP-DT Price';
export const BASE_PRICE = 'Base Price';
export const PER_KM_PRICE = 'Per km Price';

export const USER = 'User';
export const USERS = 'Users';
export const NEW_USER = 'New User';
export const ADD_NEW_USER = 'Add New User';
export const SEARCH_USER = 'Search user';
export const SEARCH = 'Search';
export const UPDATE_PROFILE = 'Update profile';
export const UPDATE_PASSWORD = 'Update password';
export const REMOVE = 'Remove';
export const DELETE = 'Delete';
export const EDIT = 'Edit';
export const DETAILS = 'Details';
export const VALID = 'Valid';
export const DESCRIPTION = 'Description';
export const UPLOAD_IMAGE = 'Upload a image';
export const CHANGE_PASSWORD = 'Change Password';
export const PAGE_NOT_FOUND = 'Sorry, page not found!';
export const CHECK_PAGE_NOT_FOUNDED =
  'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Besure to check your spelling.';
export const GO_HOME = 'Go to Home';
export const NOT_FOUND = 'Not found';
export const NO_RESULTS_FOUND = 'No results found';
export const NO_RESULTS_FOUND_FOR = 'No results found for';
export const TRY_OTHER_TYPOS = 'Try checking for typos or using complete words.';
export const CORRECT_CHECKIN_OUT = 'Please select correct times for checkin/out';
export const CHECK_IN_OUT = 'Check In / Out';
export const CHECK_IN_OUTS = 'All Check Ins / Outs';
export const UPDATE_CHECK_IN_OUT = 'Update Check In/Out';
export const ADD_CHECK_IN_OUT = 'Add Check In / Out';
export const NEW_CHECK_IN_OUT = 'New Check In / Out';
export const CHECK_IN = 'Check In';
export const CHECK_OUT = 'Check Out';
export const START_BREAK = 'Start Break';
export const END_BREAK = 'End Break';
export const UPDATE = 'Update';
export const ADD = 'Add';
export const DATE = 'Date';
export const NOTES = 'Notes';
export const SAVE = 'Save';
export const WORK_HOURS = 'Work Hours';
export const FROM_DATE = 'From Date';
export const TO_DATE = 'To Date';
export const RESET = 'Reset';
export const EXCEL = 'Excel';
export const SALARY = 'Salary';
export const PAID = 'Paid';
export const CLOSE = 'Close';
export const BREAK = 'Break';
export const HOME = 'Home';
export const PROFILE = 'Profile';
export const LOGOUT = 'Logout';

export const CARS_WASHED = 'Cars Washed';
export const CAR_WASH = 'Car Wash';
export const VALID_CAR_WASH = 'Are you sure to valid this car wash?';
export const NEW_CAR_WASH = 'New Car Wash';
export const UPDATE_CAR_WASH = 'Update Car Wash';
export const ADD_CAR_WASH = 'Add Car Wash';
export const SEARCH_PLATE = 'Search plate';
export const LICENSE_PLATE = 'License plate';
export const WASH_TYPE = 'Wash Type';
export const PRICE = 'Price';

export const OUTSIDE = 'Aussenreinigung';
export const INSIDE = 'Innenreinigung';
export const OUTINSIDE = 'Kombipaket';
export const MOTORRAD = 'Motorrad wäsche';
export const TURNAROUND = 'Turnaround';
export const QUICK_TURNAROUND = 'Quick Turnaround';
export const SPECIAL = 'Spezial';

export const USER_IS_REQUIRED = 'User is required';
export const PLATE_IS_REQUIRED = 'Number plate is required';
export const WASH_TYPE_IS_REQUIRED = 'Wash type is required';
export const SPECIAL_PRICE_IS_REQUIRED = 'Special price is required';
export const TRANSFER_PLACE_IS_REQUIRED = 'Transfer place is required';

export const SELECT_USER = 'Select User';
export const NUMBER_PLATE = 'Number Plate';
export const SELECT_LOCATION = 'Select Location';
export const SELECT_CAR_TYPE = 'Select Car Type';
export const SELECT_WASH_TYPE = 'Select Wash Type';
export const WASH = 'Wash';

export const SCANN = 'Scann';
export const DELETE_THIS = 'Are you sure to delete this';
export const CANCEL = 'Cancel';
export const YES_DELETE = 'Yes, delete!';
export const CONFIRM = 'Confirm!';
export const USER_UPDATED_SUCCESS = 'User has been updated';
export const NO_SUCH_ACTION = 'no such action:';
export const FINAL_PRICE = 'Final Price';

export const CARS_TRANSFERED = 'Cars Transfered';
export const CAR_TRANSFER = 'Car Transfer';
export const NEW_CAR_TRANSFER = 'New Car Transfer';
export const UPDATE_CAR_TRANSFER = 'Update Car Transfer';
export const ADD_CAR_TRANSFER = 'Add Car Transfer';
export const TRANSFER_METHOD_IS_REQUIRED = 'Transfer method is required';
export const TRANSFER_TYPE_IS_REQUIRED = 'Transfer type is required';
export const SELECT_TRANSFER_METHOD = 'Select Transfer Method';
export const SELECT_TRANSFER_TYPE = 'Select Transfer Type';
export const COLLECTION = 'Collection';
export const DELIVERY = 'Delivery';

export const PLAN = 'Daily Plan';
export const SAVE_PLAN = 'Save Plan';

export const TRANSFER = 'Transfer';
export const TRANSFER_METHOD = 'Transfer method';
export const TRANSFER_TYPE = 'Transfer type';
export const HPZ = 'HPZ';
export const HBP = 'HBP';
export const APDT = 'AP-DT';
export const DISTANCE = 'Distance';
export const PLACED = 'Place';

export const TRANSFR_KM = 'Transfer KM';
export const VALID_CAR_TRANSFER = 'Are you sure to valid this car transfer?';

export const POSTAL_CODE = 'PLZ';
export const STREET = 'Strasse';
export const PLACE = 'Wohnort';
export const AHV_NUMBER = 'AHV-Nummer';

export const HOURLY_PAY = 'Hourly pay';
export const TOTAL = 'Total:';

export const CAR_WASHED_6HOURS_TEXT = 'This car has been washed within 6 hours. Do you want to proceed?';
export const CAR_TRANSFERED_6HOURS_TEXT = 'This car has been transfered within 6 hours. Do you want to proceed?';
export const LOHNABRECHNUNG_EMAIL_SENT = 'Lohnabrechnung has been send succesfully to ';

export const PAYROLL = 'Lohnabrechnung';
export const PAYROLLS = 'Payrolls';
export const NEW_PAYROLL = 'New Payroll';
export const MONTH_YEAR = 'Month and Year';
export const PLACE_DATE = 'Ort, Datum';
export const ARBEITGEBER = 'Arbeitgeber';
export const WORKER = 'Arbeitnehmer';
export const BASICS = 'Grundlagen';
export const KANTON = 'Kanton';
export const BILLINGPROCEDUREG = 'Abrechnungsverfahren';
export const HOURLYPAY = 'Stundenlohn';
export const TOTAL_HOURS = 'Anzahl Stunden';
export const LOHN = 'Lohn';
export const SATZE = 'Sätze';
export const PRO_STUNDE = 'Pro Stunde';
export const PRO_MONAT = 'Pro Monat';
export const ARBEITSLOHN = 'Arbeitslohn';
export const HOLIDAYBONUSG = 'Ferienzuschlag';
export const BRUTTOLOHN = 'Bruttolohn';
export const TOTAL_ABZUGE = 'Total Abzüge';
export const ABZUGE = 'Abzüge';
export const NETTOLOHN = 'Nettolohn';
export const PDF = 'Pdf';
export const SAVE_SEND_EMAIL = 'Save and Send Email';
export const BY = 'By';
export const NEW_PLAN = 'New Plan';
export const ARE_SURE_RESTORE_USER = 'Are you sure to restore this user?';
export const ARE_SURE_RESTORE_LOCATION = 'Are you sure to restore this location?';

export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
export const TRASH = 'Trash';
export const RESTORE = 'Restore';
export const DELETE_PERMENANTLY = 'Delete Permenantly';
export const MONATSLOHN = 'Monatslohn';

export const RESET_PASSWORD = 'Reset password';
