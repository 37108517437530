import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

import { Page, Alert } from '../components';
import { useAppContext } from '../context/app-context';
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import { LOGIN_TITLE1, LOGIN_TITLE2, LOGIN_BUTTON } from '../lang/en';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const { showAlert, user } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role === 'admin') {
      setTimeout(() => {
        navigate('/dashboard/app');
      }, 2000);
    }
    if (user?.role === 'accountant') {
      setTimeout(() => {
        navigate('/accountant/payroll');
      }, 2000);
    }
    if (user?.role === 'manager') {
      setTimeout(() => {
        navigate('/manager/check-in-out');
      }, 2000);
    }
    if (user?.role === 'user') {
      setTimeout(() => {
        navigate('/user/check-in-out');
      }, 2000);
    }
  }, [user, navigate]);

  return (
    <Page title={LOGIN_BUTTON}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              {LOGIN_TITLE1}
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>{LOGIN_TITLE2}</Typography>

            <AuthSocial />
            {showAlert && <Alert />}
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
