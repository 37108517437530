import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
  Toolbar,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';

import { Alert, Page, Scrollbar, Iconify, AlertDialog } from '../components';
import CheckInOutMoreMenu from '../sections/@dashboard/checkInOut/CheckInOutsMoreMenu';
import { useAppContext } from '../context/app-context';
import {
  USER,
  CHECK_IN,
  CHECK_OUT,
  DATE,
  WORK_HOURS,
  SALARY,
  CHECK_IN_OUT,
  NOTES,
  CLOSE,
  FROM_DATE,
  TO_DATE,
  RESET,
  EXCEL,
  BREAK,
  NO_RESULTS_FOUND,
  LOCATION,
  NEW_CHECK_IN_OUT,
  CHECK_IN_OUTS,
  TOTAL,
} from '../lang/en';

const TABLE_HEAD = [
  { id: 'userId', label: `${USER}`, alignRight: false },
  { id: 'createdDate', label: `${DATE}`, alignRight: false },
  { id: 'checkIn', label: `${CHECK_IN}`, alignRight: false },
  { id: 'checkOut', label: `${CHECK_OUT}`, alignRight: false },
  { id: 'workHours', label: `${WORK_HOURS}`, alignRight: false },
  { id: 'dailySalary', label: `${SALARY}`, alignRight: false },
  { id: 'actions' },
];

const CheckInOutsAdmin = () => {
  const { showAlert, displayAlert, authFetch } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [checkInOuts, setCheckInOuts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCheckInOuts, setTotalCheckInOuts] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalSalary, setTotalSalary] = useState(0);

  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('');
  const [locations, setLocations] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [checkInOutId, setCheckInOutId] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [checkInOutDetails, setCheckInOutDetails] = useState(null);

  const [startTimeLoc, setStartTimeLoc] = useState({});
  const [endTimeLoc, setEndTimeLoc] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await authFetch.get(`user?limit=1000`);
        const { users } = data;
        setUsers(users);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchUsers();
    const fetchNameLocations = async () => {
      try {
        const { data } = await authFetch.get('location/admin');
        setLocations(data?.locations);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchNameLocations();
  }, []);

  const fetchCheckInOuts = async () => {
    try {
      const fDate = fromDate === null ? '' : fromDate;
      const tDate = toDate === null ? '' : toDate;
      const { data } = await authFetch.get(
        `check-in-out/admin?page=${page + 1}&locationId=${locationId}&userId=${userId}&from=${fDate}&to=${tDate}`
      );
      const { checkIns, totalCheckIns, totalHours, totalSalary } = data;
      setCheckInOuts(checkIns);
      setTotalCheckInOuts(totalCheckIns);
      setTotalHours(totalHours);
      setTotalSalary((+totalSalary).toFixed(2));
      setHasDeleted(false);
      setIsLoading(false);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };
  useEffect(() => {
    fetchCheckInOuts();
  }, [page, userId, locationId, fromDate, toDate, hasDeleted]);

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };
  const handleReset = () => {
    setUserId('');
    setLocationId('');
    setFromDate(null);
    setToDate(null);
  };

  const handleDelete = (deleteId) => {
    setIsDeleteOpen(true);
    setCheckInOutId(deleteId);
  };
  const handleClose = () => {
    setIsDeleteOpen(false);
  };
  const handleConfirmDelete = async () => {
    try {
      const checkInOutDelete = await authFetch.delete(`check-in-out/admin/${checkInOutId}`);
      setIsDeleteOpen(false);
      setHasDeleted(true);
      displayAlert('success', checkInOutDelete?.data?.msg);
    } catch (error) {
      setIsDeleteOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const handleOpenDetails = async (id) => {
    setIsDetailsOpen(true);
    const { data } = await authFetch.get(`check-in-out/${id}`);
    setCheckInOutDetails(data);

    const { lat, long } = data?.startTimeLocation;
    const { lat: eLat, long: eLong } = data?.endTimeLocation || { lat: '', long: '' };

    setStartTimeLoc({ lat, long });
    setEndTimeLoc({ lat: eLat, long: eLong });
  };
  const handleCloseDetails = () => setIsDetailsOpen(false);

  const emptyCheckInOuts = checkInOuts.length === 0;

  const fetchCheckInOutExcel = async () => {
    try {
      const fDate = fromDate === null ? '' : fromDate;
      const tDate = toDate === null ? '' : toDate;
      const { data } = await authFetch.get(
        `check-in-out/excel?locationId=${locationId}&userId=${userId}&from=${fDate}&to=${tDate}`
      );
      return data;
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const downloadExcel = async () => {
    try {
      const data = await fetchCheckInOutExcel();

      const worksheet = XLSX.utils.json_to_sheet(data.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[`${USER}`, `${DATE}`, `${CHECK_IN}`, `${CHECK_OUT}`, `${WORK_HOURS}`, `${SALARY}`]],
        {
          origin: 'A1',
        }
      );

      XLSX.utils.sheet_add_aoa(worksheet, [], { origin: -1 });
      XLSX.utils.sheet_add_aoa(worksheet, [[`${TOTAL}`, '', '', '', data.totalHours, data.totalSalary]], {
        origin: -1,
      });

      XLSX.writeFile(workbook, `${CHECK_IN_OUT}.xlsx`);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  return (
    <>
      {showAlert && <Alert />}
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title={CHECK_IN_OUTS}>
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  {CHECK_IN_OUTS}
                </Typography>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/dashboard/new-check-in-out"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {NEW_CHECK_IN_OUT}
                </Button>
              </Stack>

              <Card>
                <div className="filter__control">
                  <FormControl fullWidth>
                    <InputLabel id="select-user-label">{USER}</InputLabel>
                    <Select
                      labelId="select-user"
                      id="select-user"
                      value={userId}
                      label={USER}
                      onChange={(e) => {
                        setLocationId('');
                        setUserId(e.target.value);
                      }}
                      MenuProps={{ PaperProps: { sx: { maxHeight: '50vh' } } }}
                    >
                      {users.map((user, i) => (
                        <MenuItem key={i} value={user._id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="filter__control">
                  <FormControl fullWidth>
                    <InputLabel id="select-location-label">{LOCATION}</InputLabel>
                    <Select
                      id="select-location"
                      value={locationId}
                      label={LOCATION}
                      onChange={(e) => {
                        setUserId('');
                        setLocationId(e.target.value);
                      }}
                    >
                      {locations.map((location, index) => (
                        <MenuItem key={index} value={location?._id}>
                          {location?.locationName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="filter__control">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={FROM_DATE}
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(moment(newValue).format('YYYY-MM-DD'));
                      }}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'dd/MM/yyyy',
                            }}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="filter__control">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={TO_DATE}
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(moment(newValue).format('YYYY-MM-DD'));
                      }}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'dd/MM/yyyy',
                            }}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className="filter__button">
                  <Button sx={{ lineHeight: '2.8' }} variant="outlined" onClick={handleReset}>
                    {RESET}
                  </Button>
                </div>

                <div className="filter__button">
                  <Button sx={{ lineHeight: '2.8' }} variant="outlined" onClick={downloadExcel}>
                    {EXCEL}
                  </Button>
                </div>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {TABLE_HEAD.map((headCell) => (
                            <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {checkInOuts.map((checkInOut) => {
                          const { _id, user, date, startTime, endTime, workHours, dailySalary, suspect } = checkInOut;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              style={suspect ? { backgroundColor: '#ff000080' } : {}}
                            >
                              <TableCell align="left">{user}</TableCell>
                              <TableCell align="left">{date}</TableCell>
                              <TableCell align="left">{moment(startTime).format('HH:mm')}</TableCell>
                              <TableCell align="left">{moment(endTime).format('HH:mm')}</TableCell>
                              <TableCell align="left">{workHours}</TableCell>
                              <TableCell align="left">{dailySalary}</TableCell>
                              <TableCell align="right">
                                <CheckInOutMoreMenu
                                  id={_id}
                                  handleDelete={handleDelete}
                                  handleOpenDetails={handleOpenDetails}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {!emptyCheckInOuts && (
                          <TableRow hover tabIndex={-1}>
                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                              {TOTAL}
                            </TableCell>
                            <TableCell align="left" />
                            <TableCell align="left" />
                            <TableCell align="left" />
                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                              {totalHours}
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                              {totalSalary}
                            </TableCell>
                            <TableCell align="left" />
                          </TableRow>
                        )}
                      </TableBody>

                      {emptyCheckInOuts && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Typography variant="body2" align="center">
                                {NO_RESULTS_FOUND}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={totalCheckInOuts}
                  rowsPerPage={10}
                  page={page}
                  onPageChange={handleChangePage}
                  showFirstButton
                  showLastButton
                />
              </Card>
              {isDeleteOpen && (
                <AlertDialog handleClose={handleClose} handleConfirmDelete={handleConfirmDelete} text="check in out" />
              )}

              {isDetailsOpen && (
                <Dialog
                  onClose={handleCloseDetails}
                  aria-labelledby="customized-dialog-title"
                  aria-describedby="customized-dialog-description"
                  open={isDetailsOpen}
                >
                  <DialogTitle id="customized-dialog-title">
                    {moment(checkInOutDetails?.startTime).format('LL')} - {checkInOutDetails?.userId?.firstName}{' '}
                    {checkInOutDetails?.userId?.lastName}
                  </DialogTitle>
                  <DialogContent id="customized-dialog-description" dividers>
                    <p style={{ marginBottom: '8px' }}>
                      <>
                        <span style={{ fontWeight: 700 }}>{`${CHECK_IN}:`} </span>
                        {checkInOutDetails?.startTimeLocation
                          ? `${moment(checkInOutDetails?.startTime).format('HH:mm')} (${
                              checkInOutDetails?.startTimeLocation?.address?.road
                            } ${checkInOutDetails?.startTimeLocation?.address?.city})`
                          : moment(checkInOutDetails?.startTime).format('HH:mm')}
                        {/* {moment(checkInOutDetails?.startTime).format('HH:mm')}{' '} */}
                        <IconButton
                          href={`https://www.google.com/maps/search/?api=1&query=${startTimeLoc.lat},${startTimeLoc.long}`}
                          target="_blank"
                          size="small"
                          color="secondary"
                          style={{ marginTop: '-3px' }}
                        >
                          {<Iconify icon="eva:pin-fill" />}
                        </IconButton>
                      </>
                    </p>
                    <p style={{ marginBottom: '8px' }}>
                      <span style={{ fontWeight: 700 }}>{`${CHECK_OUT}:`} </span>
                      {checkInOutDetails?.endTime && (
                        <>
                          {checkInOutDetails?.endTimeLocation
                            ? `${moment(checkInOutDetails?.endTime).format('HH:mm')} (${
                                checkInOutDetails?.endTimeLocation?.address?.road
                              } ${checkInOutDetails?.endTimeLocation?.address?.city})`
                            : moment(checkInOutDetails?.endTime).format('HH:mm')}
                          {/* {moment(checkInOutDetails?.endTime).format('HH:mm')}{' '} */}
                          <IconButton
                            href={`https://www.google.com/maps/search/?api=1&query=${endTimeLoc.lat},${endTimeLoc.long}`}
                            target="_blank"
                            size="small"
                            color="secondary"
                            style={{ marginTop: '-3px' }}
                          >
                            {<Iconify icon="eva:pin-fill" />}
                          </IconButton>
                        </>
                      )}
                    </p>
                    {checkInOutDetails?.breaks.map((itemBreak, index) => {
                      return (
                        <p key={index} style={{ marginBottom: '8px' }}>
                          <span style={{ fontWeight: 700 }}>
                            {BREAK} ({index + 1}):{' '}
                          </span>
                          {moment(itemBreak?.startBreak).format('HH:mm')}
                          {' - '}
                          {moment(itemBreak?.endBreak).format('HH:mm')}
                        </p>
                      );
                    })}
                    <p style={{ marginBottom: '8px' }}>
                      <span style={{ fontWeight: 700 }}>{`${WORK_HOURS}:`} </span>
                      {checkInOutDetails?.workHours}
                    </p>
                    <p style={{ marginBottom: '8px' }}>
                      {' '}
                      <span style={{ fontWeight: 700 }}>{`${SALARY}:`} </span>
                      {checkInOutDetails?.dailySalary}
                    </p>
                    <p style={{ marginBottom: '8px' }}>
                      <span style={{ fontWeight: 700 }}>{`${NOTES}:`} </span>
                      {checkInOutDetails?.description}
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="outlined" autoFocus onClick={handleCloseDetails}>
                      {CLOSE}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Container>
          </Page>
        </>
      )}
    </>
  );
};
export default CheckInOutsAdmin;
