import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './styleAllPages.css';
import _ from 'lodash';
import moment from 'moment';
import { Box, CircularProgress, Container, Button } from '@mui/material';

import { Alert, Page, Iconify } from '../components';
import { useAppContext } from '../context/app-context';
import { PLAN, BY, NEW_PLAN } from '../lang/en';

const Plan = () => {
  const { authFetch, showAlert, displayAlert, user } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const [savedBy, setSavedBy] = useState('');
  const [dateModified, setDateModified] = useState('');

  const [state, setState] = useState({
    User: {
      title: 'User',
      items: [],
    },
  });

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const { data } = await authFetch.get(`today-plan`);
        const { todayPlan } = data;
        const { users, createdBy, lastModified } = todayPlan;
        setState(users);
        setSavedBy(createdBy);
        setDateModified(moment(lastModified).format('DD MMMM, HH:mm'));
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchPlan();
  }, []);

  return (
    <>
      {showAlert && <Alert />}
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title={PLAN}>
            <Container>
              <div className="Plan">
                {_.map(state, (data, key) => {
                  return (
                    <div key={key} className={key === 'User' ? `column users` : 'column'}>
                      <div className="plan-modified">
                        <div>
                          <h3>{data.title} </h3>
                          {key === 'User' && <p>{`${BY} ${savedBy}, ${dateModified}`}</p>}
                        </div>
                        {key === 'User' && user?.role === 'admin' && (
                          <Button
                            variant="contained"
                            component={RouterLink}
                            to="/dashboard/save-plan"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                          >
                            {NEW_PLAN}
                          </Button>
                        )}
                      </div>
                      <div className="droppable-col">
                        {data.items.map((el, index) => {
                          return (
                            <div key={index} className={`item `}>
                              {el.nameItem}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Container>
          </Page>
        </>
      )}
    </>
  );
};

export default Plan;
