import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';

import { MenuPopover } from '../../components';
import logo from '../../components/logo/rhr-logo.png';
import { useAppContext } from '../../context/app-context';

import { LOGOUT, PROFILE, HOME } from '../../lang/en';

export default function AccountPopover() {
  const { user, logOut } = useAppContext();
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    logOut();
  };

  const navigate = useNavigate();

  const toHome = () => {
    handleClose();
    let baseUrl = 'dashboard/app';
    if (user?.role === 'accountant') baseUrl = 'accountant/payroll';
    if (user?.role === 'manager') baseUrl = 'manager/check-in-out';
    if (user?.role === 'user') baseUrl = 'user/check-in-out';

    navigate(`/${baseUrl}`);
  };

  const toProfile = (userId) => {
    handleClose();
    let baseUrl = 'dashboard';
    if (user?.role === 'accountant') baseUrl = 'accountant';
    if (user?.role === 'manager') baseUrl = 'manager';
    if (user?.role === 'user') baseUrl = 'user';

    navigate(`/${baseUrl}/profile`, { state: { userId } });
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={logo} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }} noWrap>
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem key="Home" to="/dashboard/app" onClick={toHome}>
            {HOME}
          </MenuItem>

          <MenuItem key="Profile" onClick={() => toProfile(user._id)}>
            {PROFILE}
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {LOGOUT}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
