import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

import { Page, Alert } from '../components';
import { useAppContext } from '../context/app-context';
import { ResetPasswordForm } from '../sections/auth/resetPassword';
import { RESET_PASSWORD } from '../lang/en';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ResetPassword() {
  const { showAlert } = useAppContext();

  return (
    <Page title={RESET_PASSWORD}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              {RESET_PASSWORD}
            </Typography>

            {showAlert && <Alert />}
            <ResetPasswordForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
