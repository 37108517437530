import React from 'react';
import Form from '../components/Form';

function NewUser() {
  return (
    <div>
      <Form />
    </div>
  );
}

export default NewUser;
