import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Typography, Button, Grid, Card, Container, FormControl } from '@mui/material';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { Page, Alert } from '../components';
import ProfileForm from '../sections/@dashboard/user/ProfileForm';
import ProfilePasswordForm from '../sections/@dashboard/user/ProfilePasswordForm';
import { useAppContext } from '../context/app-context';
import { PROFILE, UPLOAD_IMAGE, UPDATE_PROFILE, CHANGE_PASSWORD } from '../lang/en';

import profileIMG from '../components/logo/rhr-logo.png';

export default function Profile() {
  const { state } = useLocation();
  const { authFetch, user, showAlert, displayAlert } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await authFetch.get(`user/${state?.userId}`);
        setCurrentUser(data);
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchUser();
  }, [state]);

  return (
    <>
      {showAlert && <Alert />}
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title={PROFILE}>
            <Container>
              <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                <Grid item xs={12} md={4} container direction="column" alignItems="center">
                  <Box>
                    <img
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      src={profileIMG}
                      alt="Profile"
                      width={'200px'}
                    />
                  </Box>
                  <Typography variant="h6" sx={{ width: '100%', mt: 2 }} align="center">
                    {state?.userId
                      ? `${currentUser?.firstName} ${currentUser?.lastName}`
                      : `${user?.firstName} ${user?.lastName}`}
                  </Typography>
                  {/* <FormControl sx={{ m: 1 }} variant="outlined">
                    <Button variant="contained" component="label" color="primary">
                      <CameraAltIcon /> {UPLOAD_IMAGE}
                      <input type="file" hidden />
                    </Button>
                  </FormControl> */}
                </Grid>
                <Grid item xs={12} md={8} alignItems="center">
                  <Card sx={{ p: 3 }}>
                    <Typography variant="h4" sx={{ mb: 4 }} align="center">
                      {UPDATE_PROFILE}
                    </Typography>
                    <ProfileForm userId={state?.userId} />
                  </Card>

                  <Card sx={{ mt: 8, p: 3 }}>
                    <Typography variant="h4" sx={{ mb: 4 }} align="center">
                      {CHANGE_PASSWORD}
                    </Typography>
                    <ProfilePasswordForm userId={state?.userId} />
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </Page>
        </>
      )}
    </>
  );
}
