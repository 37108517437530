import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Stack,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAppContext } from '../../../context/app-context';
import {
  TOO_SHORT,
  TOO_LONG,
  FIRST_NAME_IS_REQUIRED,
  LAST_NAME_IS_REQUIRED,
  EMAIL_IS_REQUIRED,
  EMAIL_VALID,
  LOCATION_IS_REQUIRED,
  ROLE_IS_REQUIRED,
  PHONE_VALID,
  STATUS_IS_REQUIRED,
  FIRST_NAME,
  LAST_NAME,
  LOCATION,
  PHONE_NUMBER,
  EMAIL_ADDRESS,
  ROLE,
  SELECT_STATUS,
  UPDATE_PROFILE,
  DESCRIPTION,
  POSTAL_CODE,
  STREET,
  PLACE,
  AHV_NUMBER,
  HOURLY_PAY,
} from '../../../lang/en';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

ProfileForm.propTypes = {
  userId: PropTypes.string,
};

const rolesArray = ['user', 'accountant', 'manager', 'admin'];

export default function ProfileForm({ userId }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [locations, setLocations] = useState([]);
  const [roles, setRoles] = useState([]);
  const { updateUser, authFetch, displayAlert, user } = useAppContext();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await authFetch.get(`user/${userId}`);
        setCurrentUser(data);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchUser();

    const fetchNameLocations = async () => {
      try {
        const { data } = await authFetch.get('location/admin');
        setLocations(data?.locations);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchNameLocations();
    setRoles(rolesArray);
  }, [userId]);

  useEffect(() => {
    if (currentUser) {
      formik.setValues(currentUser);
    }
  }, [currentUser, userId]);

  const ProfileSchema = Yup.object().shape({
    firstName: Yup.string().min(2, TOO_SHORT).max(50, TOO_LONG).required(FIRST_NAME_IS_REQUIRED),
    lastName: Yup.string().min(2, TOO_SHORT).max(50, TOO_LONG).required(LAST_NAME_IS_REQUIRED),
    locationId: Yup.string().required(LOCATION_IS_REQUIRED),
    role: Yup.string().required(ROLE_IS_REQUIRED),
    email: Yup.string().email(EMAIL_VALID).required(EMAIL_IS_REQUIRED),
    phone: Yup.string().matches(phoneRegExp, PHONE_VALID),
    active: Yup.string().required(STATUS_IS_REQUIRED),
    hourlyPay: Yup.number().min(0),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      locationId: '',
      role: '',
      email: '',
      phone: '',
      street: '',
      postalCode: '',
      place: '',
      ahv: '',
      description: '',
      active: '',
      hourlyPay: '',
    },
    validationSchema: ProfileSchema,
    onSubmit: (values, { setSubmitting }) => {
      (async () => {
        try {
          const newUser = {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            locationId: formik.values.locationId,
            role: formik.values.role,
            email: formik.values.email,
            phone: formik.values.phone,
            street: formik.values.street,
            postalCode: formik.values.postalCode,
            place: formik.values.place,
            ahv: formik.values.ahv,
            description: formik.values.description,
            active: formik.values.active,
            hourlyPay: formik.values.hourlyPay,
          };
          await updateUser(userId, newUser, setSubmitting);
        } catch (error) {
          displayAlert('danger', error?.response?.data?.msg, setSubmitting);
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label={FIRST_NAME}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                label={LAST_NAME}
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>
            {user?.role === 'admin' && (
              <>
                <FormControl fullWidth error={Boolean(touched.locationId && errors.locationId)}>
                  <InputLabel id="locationId-label">{LOCATION}</InputLabel>
                  <Select id="select-locationId" label={LOCATION} {...getFieldProps('locationId')}>
                    {locations.map((location, index) => (
                      <MenuItem key={index} value={location?._id}>
                        {location?.locationName}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(touched.locationId && errors.locationId) && (
                    <FormHelperText>{errors.locationId}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
                  <InputLabel id="role-label">{ROLE}</InputLabel>
                  <Select id="select-role" label={ROLE} {...getFieldProps('role')} sx={{ textTransform: 'capitalize' }}>
                    {roles.map((item, index) => (
                      <MenuItem key={index} value={item} sx={{ textTransform: 'capitalize' }}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(touched.role && errors.role) && <FormHelperText>{errors.role}</FormHelperText>}
                </FormControl>
              </>
            )}

            <TextField
              fullWidth
              type="email"
              label={EMAIL_ADDRESS}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              label={PHONE_NUMBER}
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            <TextField
              fullWidth
              label={STREET}
              {...getFieldProps('street')}
              error={Boolean(touched.street && errors.street)}
              helperText={touched.street && errors.street}
            />

            <TextField
              fullWidth
              label={POSTAL_CODE}
              {...getFieldProps('postalCode')}
              error={Boolean(touched.postalCode && errors.postalCode)}
              helperText={touched.postalCode && errors.postalCode}
            />
            <TextField
              fullWidth
              label={PLACE}
              {...getFieldProps('place')}
              error={Boolean(touched.place && errors.place)}
              helperText={touched.place && errors.place}
            />
            <TextField
              fullWidth
              label={AHV_NUMBER}
              {...getFieldProps('ahv')}
              error={Boolean(touched.ahv && errors.ahv)}
              helperText={touched.ahv && errors.ahv}
            />

            {user?.role === 'admin' && (
              <>
                <TextField
                  fullWidth
                  type="number"
                  label={HOURLY_PAY}
                  {...getFieldProps('hourlyPay')}
                  error={Boolean(touched.hourlyPay && errors.hourlyPay)}
                  helperText={touched.hourlyPay && errors.hourlyPay}
                />
                <FormControl error={Boolean(touched.active && errors.active)}>
                  <FormLabel id="active">{SELECT_STATUS}</FormLabel>
                  <RadioGroup row aria-labelledby="active" name="active">
                    <FormControlLabel
                      checked={getFieldProps('active').value === 'active'}
                      value={'active'}
                      control={<Radio />}
                      label="Active"
                      onChange={getFieldProps('active').onChange}
                    />
                    <FormControlLabel
                      checked={getFieldProps('active').value === 'Inactive'}
                      value={'Inactive'}
                      control={<Radio />}
                      label="Inactive"
                      onChange={getFieldProps('active').onChange}
                    />
                  </RadioGroup>
                  {Boolean(touched.active && errors.active) && <FormHelperText>{errors.active}</FormHelperText>}
                </FormControl>
              </>
            )}

            <TextField fullWidth type="text" label={DESCRIPTION} {...getFieldProps('description')} rows={4} multiline />

            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              {UPDATE_PROFILE}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
