import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import {
  Stack,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  Button,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Alert } from '../../../components';
import { useAppContext } from '../../../context/app-context';
import {
  LOCATION_IS_REQUIRED,
  LOCATION_TYPE_IS_REQUIRED,
  CAR_TYPE_IS_REQUIRED,
  TYPE_PRICE,
  MUST_BE_NUMBER,
  REMOVE,
  CAR_TYPE,
  LOCATION_NAME,
  SELECT_LOCATION_TYPE,
  WITH_TRANSFER,
  WITHOUT_TRANSFER,
  OUTSIDE_PRICE,
  INSIDE_PRICE,
  OUT_INSIDE_PRICE,
  MOTORRAD_PRICE,
  TURNAROUND_PRICE,
  QUICK_TURNAROUND_PRICE,
  HZP_PRICE,
  HBP_PRICE,
  APDT_PRICE,
  BASE_PRICE,
  PER_KM_PRICE,
  ADD_NEW_CAR_TYPE,
  UPDATE_LOCATION,
} from '../../../lang/en';

EditLocationForm.propTypes = {
  locationId: PropTypes.string,
};

export default function EditLocationForm({ locationId }) {
  const { showAlert, displayAlert, authFetch } = useAppContext();
  const navigate = useNavigate();

  const [currentLocation, setCurrentLocation] = useState(null);
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const { data } = await authFetch.get(`location/${locationId}`);
        setCurrentLocation(data);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchLocation();
  }, []);
  useEffect(() => {
    if (currentLocation) {
      formik.setValues(currentLocation);
    }
  }, [currentLocation]);

  const LocationSchema = Yup.object().shape({
    locationName: Yup.string().required(LOCATION_IS_REQUIRED),
    locationType: Yup.string().required(LOCATION_TYPE_IS_REQUIRED),

    carType: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(CAR_TYPE_IS_REQUIRED),
        wash: Yup.object().shape({
          outside: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
          inside: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
          outInside: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
          motorrad: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
          turnaround: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
          quickTurnaround: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
        }),
        transfer: Yup.object().shape({
          hzp: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
          hbp: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
          apdt: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
          base: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
          perkm: Yup.number().typeError(MUST_BE_NUMBER).positive(TYPE_PRICE),
        }),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      locationName: '',
      locationType: '',
      carType: [
        {
          name: '',
          wash: {
            outside: '',
            inside: '',
            outInside: '',
            motorrad: '',
            turnaround: '',
            quickTurnaround: '',
          },
          transfer: {
            hzp: '',
            hbp: '',
            apdt: '',
            base: '',
            perkm: '',
          },
        },
      ],
    },
    validationSchema: LocationSchema,
    onSubmit: (values, { setSubmitting }) => {
      (async () => {
        try {
          const location = {
            locationName: formik.values.locationName,
            locationType: formik.values.locationType,
            carType: formik.values.carType,
          };
          const { locationName, locationType, carType } = location;

          const editedLocation = await authFetch.patch(`/location/admin/${locationId}`, {
            locationName,
            locationType,
            carType,
          });
          if (editedLocation !== undefined) {
            setTimeout(() => {
              navigate('/dashboard/locations', { replace: true });
            }, 3000);
            displayAlert('success', editedLocation?.data?.msg, setSubmitting);
          }
        } catch (error) {
          displayAlert('danger', error?.response?.data?.msg, setSubmitting);
        }
      })();
    },
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {showAlert && <Alert />}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="locationName"
              type="text"
              label={LOCATION_NAME}
              {...getFieldProps('locationName')}
              error={Boolean(touched.locationName && errors.locationName)}
              helperText={touched.locationName && errors.locationName}
            />

            <FormControl error={Boolean(touched.locationType && errors.locationType)}>
              <FormLabel id="location-type">{SELECT_LOCATION_TYPE}</FormLabel>
              <RadioGroup row aria-labelledby="location-type" name="locationType">
                <FormControlLabel
                  checked={getFieldProps('locationType').value === 'noTransfer'}
                  value="noTransfer"
                  control={<Radio />}
                  label={WITHOUT_TRANSFER}
                  onChange={getFieldProps('locationType').onChange}
                />
                <FormControlLabel
                  checked={getFieldProps('locationType').value === 'withTransfer'}
                  value="withTransfer"
                  control={<Radio />}
                  label={WITH_TRANSFER}
                  onChange={getFieldProps('locationType').onChange}
                />
              </RadioGroup>
              {Boolean(touched.locationType && errors.locationType) && (
                <FormHelperText>{errors.locationType}</FormHelperText>
              )}
            </FormControl>

            {getFieldProps('locationType').value !== '' && (
              <FieldArray
                name="carType"
                render={(arrayHelpers) => (
                  <Stack spacing={2}>
                    {getFieldProps('carType').value.map((item, index) => (
                      <Stack spacing={2} key={index}>
                        <Stack direction="row" spacing={2}>
                          <TextField
                            fullWidth
                            type="text"
                            label={CAR_TYPE}
                            name={`carType.${index}.name`}
                            value={getFieldProps(`carType.${index}.name`).value}
                            onChange={getFieldProps(`carType.${index}.name`).onChange}
                            error={Boolean(touched.carType?.[index]?.name && errors.carType?.[index]?.name)}
                            helperText={touched.carType?.[index]?.name && errors.carType?.[index]?.name}
                          />

                          <Button color="error" variant="outlined" onClick={() => arrayHelpers.remove(index)}>
                            {REMOVE}
                          </Button>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <TextField
                            fullWidth
                            type="text"
                            label={OUTSIDE_PRICE}
                            name={`carType.${index}.wash.outside`}
                            value={getFieldProps(`carType.${index}.wash.outside`).value}
                            onChange={getFieldProps(`carType.${index}.wash.outside`).onChange}
                            error={Boolean(
                              touched.carType?.[index]?.wash.outside && errors.carType?.[index]?.wash.outside
                            )}
                            helperText={touched.carType?.[index]?.wash.outside && errors.carType?.[index]?.wash.outside}
                          />
                          <TextField
                            fullWidth
                            type="text"
                            label={INSIDE_PRICE}
                            name={`carType.${index}.wash.inside`}
                            value={getFieldProps(`carType.${index}.wash.inside`).value}
                            onChange={getFieldProps(`carType.${index}.wash.inside`).onChange}
                            error={Boolean(
                              touched.carType?.[index]?.wash.inside && errors.carType?.[index]?.wash.inside
                            )}
                            helperText={touched.carType?.[index]?.wash.inside && errors.carType?.[index]?.wash.inside}
                          />
                          <TextField
                            fullWidth
                            type="text"
                            label={OUT_INSIDE_PRICE}
                            name={`carType.${index}.wash.outInside`}
                            value={getFieldProps(`carType.${index}.wash.outInside`).value}
                            onChange={getFieldProps(`carType.${index}.wash.outInside`).onChange}
                            error={Boolean(
                              touched.carType?.[index]?.wash.outInside && errors.carType?.[index]?.wash.outInside
                            )}
                            helperText={
                              touched.carType?.[index]?.wash.outInside && errors.carType?.[index]?.wash.outInside
                            }
                          />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <TextField
                            fullWidth
                            type="text"
                            label={MOTORRAD_PRICE}
                            name={`carType.${index}.wash.motorrad`}
                            value={getFieldProps(`carType.${index}.wash.motorrad`).value}
                            onChange={getFieldProps(`carType.${index}.wash.motorrad`).onChange}
                            error={Boolean(
                              touched.carType?.[index]?.wash.motorrad && errors.carType?.[index]?.wash.motorrad
                            )}
                            helperText={
                              touched.carType?.[index]?.wash.motorrad && errors.carType?.[index]?.wash.motorrad
                            }
                          />
                          <TextField
                            fullWidth
                            type="text"
                            label={TURNAROUND_PRICE}
                            name={`carType.${index}.wash.turnaround`}
                            value={getFieldProps(`carType.${index}.wash.turnaround`).value}
                            onChange={getFieldProps(`carType.${index}.wash.turnaround`).onChange}
                            error={Boolean(
                              touched.carType?.[index]?.wash.turnaround && errors.carType?.[index]?.wash.turnaround
                            )}
                            helperText={
                              touched.carType?.[index]?.wash.turnaround && errors.carType?.[index]?.wash.turnaround
                            }
                          />
                          <TextField
                            fullWidth
                            type="text"
                            label={QUICK_TURNAROUND_PRICE}
                            name={`carType.${index}.wash.quickTurnaround`}
                            value={getFieldProps(`carType.${index}.wash.quickTurnaround`).value}
                            onChange={getFieldProps(`carType.${index}.wash.quickTurnaround`).onChange}
                            error={Boolean(
                              touched.carType?.[index]?.wash.quickTurnaround &&
                                errors.carType?.[index]?.wash.quickTurnaround
                            )}
                            helperText={
                              touched.carType?.[index]?.wash.quickTurnaround &&
                              errors.carType?.[index]?.wash.quickTurnaround
                            }
                          />
                        </Stack>
                        {getFieldProps('locationType').value === 'withTransfer' && (
                          <>
                            <Stack direction="row" spacing={2}>
                              <TextField
                                fullWidth
                                type="text"
                                label={HZP_PRICE}
                                name={`carType.${index}.transfer.hzp`}
                                value={getFieldProps(`carType.${index}.transfer.hzp`).value}
                                onChange={getFieldProps(`carType.${index}.transfer.hzp`).onChange}
                                error={Boolean(
                                  touched.carType?.[index]?.transfer.hzp && errors.carType?.[index]?.transfer.hzp
                                )}
                                helperText={
                                  touched.carType?.[index]?.transfer.hzp && errors.carType?.[index]?.transfer.hzp
                                }
                              />
                              <TextField
                                fullWidth
                                type="text"
                                label={HBP_PRICE}
                                name={`carType.${index}.transfer.hbp`}
                                value={getFieldProps(`carType.${index}.transfer.hbp`).value}
                                onChange={getFieldProps(`carType.${index}.transfer.hbp`).onChange}
                                error={Boolean(
                                  touched.carType?.[index]?.transfer.hbp && errors.carType?.[index]?.transfer.hbp
                                )}
                                helperText={
                                  touched.carType?.[index]?.transfer.hbp && errors.carType?.[index]?.transfer.hbp
                                }
                              />
                              <TextField
                                fullWidth
                                type="text"
                                label={APDT_PRICE}
                                name={`carType.${index}.transfer.apdt`}
                                value={getFieldProps(`carType.${index}.transfer.apdt`).value}
                                onChange={getFieldProps(`carType.${index}.transfer.apdt`).onChange}
                                error={Boolean(
                                  touched.carType?.[index]?.transfer.apdt && errors.carType?.[index]?.transfer.apdt
                                )}
                                helperText={
                                  touched.carType?.[index]?.transfer.apdt && errors.carType?.[index]?.transfer.apdt
                                }
                              />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                              <TextField
                                fullWidth
                                type="text"
                                label={BASE_PRICE}
                                name={`carType.${index}.transfer.base`}
                                value={getFieldProps(`carType.${index}.transfer.base`).value}
                                onChange={getFieldProps(`carType.${index}.transfer.base`).onChange}
                                error={Boolean(
                                  touched.carType?.[index]?.transfer.base && errors.carType?.[index]?.transfer.base
                                )}
                                helperText={
                                  touched.carType?.[index]?.transfer.base && errors.carType?.[index]?.transfer.base
                                }
                              />
                              <TextField
                                fullWidth
                                type="text"
                                label={PER_KM_PRICE}
                                name={`carType.${index}.transfer.perkm`}
                                value={getFieldProps(`carType.${index}.transfer.perkm`).value}
                                onChange={getFieldProps(`carType.${index}.transfer.perkm`).onChange}
                                error={Boolean(
                                  touched.carType?.[index]?.transfer.perkm && errors.carType?.[index]?.transfer.perkm
                                )}
                                helperText={
                                  touched.carType?.[index]?.transfer.perkm && errors.carType?.[index]?.transfer.perkm
                                }
                              />
                            </Stack>
                          </>
                        )}

                        <Divider />
                      </Stack>
                    ))}
                    <Button
                      fullWidth
                      sx={{ marginTop: '10px', marginBottom: '20px' }}
                      variant="outlined"
                      onClick={() => {
                        arrayHelpers.push({
                          name: '',
                          wash: {
                            outside: '',
                            inside: '',
                            outInside: '',
                            motorrad: '',
                            turnaround: '',
                            quickTurnaround: '',
                          },
                          transfer: {
                            hzp: '',
                            hbp: '',
                            apdt: '',
                            base: '',
                            perkm: '',
                          },
                        });
                      }}
                    >
                      {ADD_NEW_CAR_TYPE}
                    </Button>
                  </Stack>
                )}
              />
            )}
          </Stack>
          <Stack mt={3}>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              {UPDATE_LOCATION}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
