import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Box,
  CircularProgress,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
  Toolbar,
  styled,
} from '@mui/material';

import { Page, Scrollbar, Iconify, Alert } from '../components';
import { CarWashUserMoreMenu } from '../sections/@dashboard/carWash';
import { useAppContext } from '../context/app-context';
import {
  CARS_WASHED,
  NEW_CAR_WASH,
  USER,
  NO_RESULTS_FOUND,
  FROM_DATE,
  TO_DATE,
  RESET,
  DATE,
  LICENSE_PLATE,
  CAR_TYPE,
  WASH_TYPE,
  OUTSIDE,
  INSIDE,
  OUTINSIDE,
  MOTORRAD,
  TURNAROUND,
  QUICK_TURNAROUND,
  SPECIAL,
} from '../lang/en';

const TABLE_HEAD = [
  { id: 'user', label: `${USER}`, alignRight: false },
  { id: 'createdDate', label: `${DATE}`, alignRight: false },
  { id: 'licensePlate', label: `${LICENSE_PLATE}`, alignRight: false },
  { id: 'carType', label: `${CAR_TYPE}`, alignRight: false },
  { id: 'washType', label: `${WASH_TYPE}`, alignRight: false },
];

const CarsWashedUser = () => {
  const [baseUrl, setBaseUrl] = useState([]);

  const { showAlert, displayAlert, authFetch, user } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [carsWash, setCarsWash] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCarsWash, setTotalCarsWash] = useState(0);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    if (user?.role === 'admin') {
      setBaseUrl('dashboard');
    }
    if (user?.role === 'manager') {
      setBaseUrl('manager');
    }
    if (user?.role === 'user') {
      setBaseUrl('user');
    }
    const fetchCarsWash = async () => {
      try {
        const fDate = fromDate === null ? '' : fromDate;
        const tDate = toDate === null ? '' : toDate;
        const { data } = await authFetch.get(`car-wash/user/${user?._id}/?page=${page + 1}&from=${fDate}&to=${tDate}`);
        const { carWashed, totalCarWashed } = data;

        setCarsWash(carWashed);
        setTotalCarsWash(totalCarWashed);
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchCarsWash();
  }, [page, fromDate, toDate]);

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };
  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
  };

  const emptyCarsWash = carsWash.length === 0;

  return (
    <>
      {showAlert && <Alert />}

      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <Page title={CARS_WASHED}>
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {CARS_WASHED}
              </Typography>
              <Button
                variant="contained"
                component={RouterLink}
                to={`/${baseUrl}/car-wash`}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                {NEW_CAR_WASH}
              </Button>
            </Stack>
            <Card>
              <div className="filter__control">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={FROM_DATE}
                    value={fromDate}
                    onChange={(newValue) => {
                      setFromDate(moment(newValue).format('YYYY-MM-DD'));
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'dd/MM/yyyy',
                          }}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="filter__control">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={TO_DATE}
                    value={toDate}
                    onChange={(newValue) => {
                      setToDate(moment(newValue).format('YYYY-MM-DD'));
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'dd/MM/yyyy',
                          }}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="filter__button">
                <Button sx={{ lineHeight: '2.8' }} variant="outlined" onClick={handleReset}>
                  {RESET}
                </Button>
              </div>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {carsWash.map((carWash) => {
                        const { _id, user, date, licensePlate, carType, washType, suspect } = carWash;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            style={suspect ? { backgroundColor: '#ff000080' } : {}}
                          >
                            <TableCell align="left">{user}</TableCell>
                            <TableCell align="left">{moment(date).format('DD MMMM, HH:mm')}</TableCell>
                            <TableCell align="left">{licensePlate}</TableCell>
                            <TableCell align="left">{carType}</TableCell>
                            <TableCell align="left"> {washType}</TableCell>

                            <TableCell align="right">
                              <CarWashUserMoreMenu id={_id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    {emptyCarsWash && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Typography variant="body2" align="center">
                              {NO_RESULTS_FOUND}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={totalCarsWash}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
};
export default CarsWashedUser;
