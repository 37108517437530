import * as Yup from 'yup';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import Webcam from 'react-webcam';
import FormData from 'form-data';
import './styles.css';
import { Stack, TextField, FormControl, FormLabel, MenuItem, Select, FormHelperText, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Alert, AlertDialogText } from '../../../components';
import { useAppContext } from '../../../context/app-context';
import {
  OUTSIDE,
  INSIDE,
  OUTINSIDE,
  MOTORRAD,
  TURNAROUND,
  QUICK_TURNAROUND,
  SPECIAL,
  PLATE_IS_REQUIRED,
  WASH_TYPE_IS_REQUIRED,
  SPECIAL_PRICE_IS_REQUIRED,
  LOCATION_TYPE_IS_REQUIRED,
  CAR_TYPE_IS_REQUIRED,
  MUST_BE_NUMBER,
  PRICE,
  WASH,
  SCANN,
  NUMBER_PLATE,
  SELECT_LOCATION,
  SELECT_CAR_TYPE,
  SELECT_WASH_TYPE,
  TYPE_PRICE,
  CAR_WASHED_6HOURS_TEXT,
} from '../../../lang/en';

const videoConstraints = {
  width: 400,
  height: 200,
  // facingMode: { exact: 'user' },
  facingMode: { exact: 'environment' },
};

const washTypesNames = {
  outside: OUTSIDE,
  inside: INSIDE,
  outInside: OUTINSIDE,
  motorrad: MOTORRAD,
  turnaround: TURNAROUND,
  quickTurnaround: QUICK_TURNAROUND,
};

export default function CarWashForm() {
  const { showAlert, displayAlert, authFetch, user } = useAppContext();
  const [locations, setLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({});
  const [washTypes, setWashTypes] = useState([]);
  const webcamRef = useRef(null);
  const [isSuspectOpen, setIsSuspectOpen] = useState(false);

  const navigate = useNavigate();

  const handleCloseSuspect = () => {
    setIsSuspectOpen(false);
  };
  const handleConfirmSuspect = async () => {
    try {
      const carWash = {
        userId: user._id,
        licensePlate: formik.values.licensePlate,
        locationId: formik.values.locationId,
        carType: formik.values.carType,
        washType: formik.values.washType,
        specialPrice: formik.values.specialPrice,
        acceptSuspect: true,
      };
      const newCarWash = await authFetch.post('/car-wash/', carWash);

      setIsSuspectOpen(false);

      if (newCarWash?.data?.carWash?._id !== undefined) {
        setTimeout(() => {
          navigate('/user/cars-washed-user', { replace: true });
        }, 3000);
        displayAlert('success', newCarWash?.data?.msg);
      }
    } catch (error) {
      setIsSuspectOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const CarWashSchema = Yup.object().shape({
    licensePlate: Yup.string().required(PLATE_IS_REQUIRED),
    locationId: Yup.string().required(LOCATION_TYPE_IS_REQUIRED),
    carType: Yup.string().required(CAR_TYPE_IS_REQUIRED),
    washType: Yup.string().required(WASH_TYPE_IS_REQUIRED),
    specialPrice: Yup.number().when('washType', {
      is: (washType) => washType === 'special',
      then: Yup.number().typeError(MUST_BE_NUMBER).required(SPECIAL_PRICE_IS_REQUIRED).positive(TYPE_PRICE),
    }),
  });

  const formik = useFormik({
    initialValues: {
      userId: user._id,
      licensePlate: '',
      locationId: '',
      carType: '',
      washType: '',
      specialPrice: '',
    },
    validationSchema: CarWashSchema,
    onSubmit: (values, { setSubmitting }) => {
      (async () => {
        try {
          const carWash = {
            userId: user._id,
            licensePlate: formik.values.licensePlate,
            locationId: formik.values.locationId,
            carType: formik.values.carType,
            washType: formik.values.washType,
            specialPrice: formik.values.specialPrice,
          };
          const newCarWash = await authFetch.post('/car-wash/', carWash);

          if (newCarWash?.data?.suspected) {
            setIsSuspectOpen(true);
          }
          if (newCarWash?.data?.carWash?._id !== undefined) {
            setTimeout(() => {
              navigate('/user/cars-washed-user', { replace: true });
            }, 3000);
            displayAlert('success', newCarWash?.data?.msg, setSubmitting);
          }
        } catch (error) {
          displayAlert('danger', error?.response?.data?.msg, setSubmitting);
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, isSubmitting } = formik;

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const { data } = await authFetch.get(`location`);
        const { locations } = data;
        setLocations(locations);

        const currentLocation = locations?.find((location) => location._id === user.locationId);
        setCurrentLocation(currentLocation);
        formik.setFieldValue('locationId', currentLocation._id);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchCurrentLocation = async () => {
      const currentLocation = locations?.find((location) => location._id === formik.values.locationId);
      setCurrentLocation(currentLocation);
      if (currentLocation?.carType !== undefined) formik.setFieldValue('carType', currentLocation.carType[0].name);

      const currentCarType = currentLocation?.carType?.find((cType) => cType.name === formik.values.carType);
      const currentWashTypes = currentCarType?.wash
        ? Object.entries(currentCarType?.wash).filter((item) => item[1] !== '')
        : null;
      setWashTypes(currentWashTypes);
      if (currentWashTypes !== null) formik.setFieldValue('washType', currentWashTypes[0][0]);
    };
    fetchCurrentLocation();
    formik.setFieldValue('specialPrice', '');
  }, [formik.values.locationId]);

  useEffect(() => {
    const fetchWashTypes = async () => {
      const currentCarType = currentLocation?.carType?.find((cType) => cType.name === formik.values.carType);
      const currentWashTypes = currentCarType?.wash
        ? Object.entries(currentCarType?.wash).filter((item) => item[1] !== '')
        : null;
      setWashTypes(currentWashTypes);
      if (currentWashTypes !== null) formik.setFieldValue('washType', currentWashTypes[0][0]);
    };
    fetchWashTypes();
  }, [formik.values.carType]);

  const handleScan = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const body = new FormData();
    body.append('upload', imageSrc);
    body.append('regions', 'ch');

    fetch('https://api.platerecognizer.com/v1/plate-reader/', {
      method: 'POST',
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
      body,
    })
      .then((res) => res.json())
      .then((json) => {
        if (json?.results[0]?.plate !== undefined) {
          formik.setFieldValue('licensePlate', (json?.results[0]?.plate).toUpperCase());
        } else {
          formik.setFieldValue('licensePlate', '');
        }
      })
      .catch((error) => {
        displayAlert('danger', error?.response?.data?.msg);
      });
  }, [webcamRef]);

  return (
    <>
      {showAlert && <Alert />}
      <div id="car-scann">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          forceScreenshotSourceSize="true"
          videoConstraints={videoConstraints}
        />
        <Button fullWidth size="large" type="submit" variant="outlined" onClick={handleScan}>
          {SCANN}
        </Button>
      </div>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="licensePlate"
              type="text"
              label={NUMBER_PLATE}
              {...getFieldProps('licensePlate')}
              error={Boolean(touched.licensePlate && errors.licensePlate)}
              helperText={touched.licensePlate && errors.licensePlate}
            />

            <FormControl fullWidth error={Boolean(touched.locationId && errors.locationId)}>
              <FormLabel id="locationId-label">{SELECT_LOCATION}</FormLabel>
              <Select id="select-locationId" {...getFieldProps('locationId')}>
                {locations.map((location, index) => (
                  <MenuItem key={index} value={location?._id}>
                    {location?.locationName}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(touched.locationId && errors.locationId) && <FormHelperText>{errors.locationId}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.carType && errors.carType)}>
              <FormLabel id="carType-label">{SELECT_CAR_TYPE}</FormLabel>
              <Select id="select-carType" {...getFieldProps('carType')}>
                {currentLocation?.carType?.map((cType, index) => (
                  <MenuItem key={index} value={cType?.name}>
                    {cType?.name}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(touched.carType && errors.carType) && <FormHelperText>{errors.carType}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.washType && errors.washType)}>
              <FormLabel id="washType-label">{SELECT_WASH_TYPE}</FormLabel>
              <Select id="select-washType" {...getFieldProps('washType')}>
                {washTypes?.map((washType, index) => (
                  <MenuItem key={index} value={washType[0]}>
                    {washTypesNames[washType[0]]}
                  </MenuItem>
                ))}

                <MenuItem value="special">{SPECIAL}</MenuItem>
              </Select>

              {Boolean(touched.washType && errors.washType) && <FormHelperText>{errors.washType}</FormHelperText>}
            </FormControl>

            {getFieldProps('washType').value === 'special' && (
              <TextField
                fullWidth
                autoComplete="Price"
                type="number"
                label={PRICE}
                {...getFieldProps('specialPrice')}
                error={Boolean(touched.specialPrice && errors.specialPrice)}
                helperText={touched.specialPrice && errors.specialPrice}
              />
            )}
          </Stack>
          <Stack mt={3}>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              {WASH}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      {isSuspectOpen && (
        <AlertDialogText
          handleClose={handleCloseSuspect}
          handleConfirm={handleConfirmSuspect}
          text={CAR_WASHED_6HOURS_TEXT}
        />
      )}
    </>
  );
}
