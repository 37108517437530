import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';

import { DELETE_THIS, CANCEL, YES_DELETE } from '../lang/en';

AlertDialog.propTypes = {
  handleClose: PropTypes.func,
  handleConfirmDelete: PropTypes.func,
  text: PropTypes.string,
};

export default function AlertDialog({ handleClose, handleConfirmDelete, text }) {
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`${DELETE_THIS} ${text}?`}</DialogTitle>
        <DialogActions>
          <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleConfirmDelete} color="error">
            {YES_DELETE}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            {CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
