import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { CANCEL } from '../lang/en';

ModalAlert.propTypes = {
  text: PropTypes.string,
  handleModalClose: PropTypes.func,
};

export default function ModalAlert({ handleModalClose, text }) {
  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => {
    setOpenModal(false);
    handleModalClose(false);
  };
  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="error">
            {CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
