import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Card, Stack, Container, Typography } from '@mui/material';

import { NewCarWashForm, EditCarWashForm } from '../sections/@dashboard/carWash';
import { Page } from '../components';
import { UPDATE_CAR_WASH, ADD_CAR_WASH, CAR_WASH } from '../lang/en';

const NewCarWash = () => {
  const { state } = useLocation();

  return (
    <Page title={CAR_WASH}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {state?.carWashId ? UPDATE_CAR_WASH : ADD_CAR_WASH}
          </Typography>
        </Stack>
        <Card style={{ padding: '20px' }}>
          {state?.carWashId ? <EditCarWashForm carWashId={state?.carWashId} /> : <NewCarWashForm />}
        </Card>
      </Container>
    </Page>
  );
};
export default NewCarWash;
