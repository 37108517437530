import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Alert, Iconify } from '../../../components';
import { useAppContext } from '../../../context/app-context';
import {
  FIRST_NAME,
  FIRST_NAME_IS_REQUIRED,
  LAST_NAME,
  LAST_NAME_IS_REQUIRED,
  EMAIL_ADDRESS,
  EMAIL_IS_REQUIRED,
  EMAIL_VALID,
  PASSWORD,
  PASSWORD_IS_REQUIRED,
  LOCATION,
  LOCATION_IS_REQUIRED,
  ROLE,
  ROLE_IS_REQUIRED,
  ADD_NEW_USER,
  TOO_SHORT,
  TOO_LONG,
} from '../../../lang/en';

const rolesArray = ['user', 'accountant', 'manager', 'admin'];

export default function RegisterForm() {
  const navigate = useNavigate();
  const { showAlert, displayAlert, authFetch } = useAppContext();

  const [showPassword, setShowPassword] = useState(false);
  const [locations, setLocations] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchNameLocations = async () => {
      try {
        const { data } = await authFetch.get('location/admin');
        setLocations(data?.locations);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchNameLocations();
    setRoles(rolesArray);
  }, []);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(3, TOO_SHORT).max(50, TOO_LONG).required(FIRST_NAME_IS_REQUIRED),
    lastName: Yup.string().min(3, TOO_SHORT).max(50, TOO_LONG).required(LAST_NAME_IS_REQUIRED),
    locationId: Yup.string().required(LOCATION_IS_REQUIRED),
    role: Yup.string().required(ROLE_IS_REQUIRED),
    email: Yup.string().email(EMAIL_VALID).required(EMAIL_IS_REQUIRED),
    password: Yup.string().required(PASSWORD_IS_REQUIRED),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      locationId: '',
      role: '',
      email: '',
      password: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, { setSubmitting }) => {
      (async () => {
        try {
          const newUser = {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            locationId: formik.values.locationId,
            role: formik.values.role,
            email: formik.values.email,
            password: formik.values.password,
          };
          const createdUser = await authFetch.post('user', newUser);
          if (createdUser !== undefined) {
            setTimeout(() => {
              navigate('/dashboard/user', { replace: true });
            }, 3000);
            displayAlert('success', createdUser.data.msg, setSubmitting);
          }
        } catch (error) {
          displayAlert('danger', error?.response?.data?.msg, setSubmitting);
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {showAlert && <Alert />}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label={FIRST_NAME}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                label={LAST_NAME}
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <FormControl fullWidth error={Boolean(touched.locationId && errors.locationId)}>
              <InputLabel id="locationId-label">{LOCATION}</InputLabel>
              <Select id="select-locationId" label={LOCATION} {...getFieldProps('locationId')}>
                {locations.map((location, index) => (
                  <MenuItem key={index} value={location?._id}>
                    {location?.locationName}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(touched.locationId && errors.locationId) && <FormHelperText>{errors.locationId}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
              <InputLabel id="role-label">{ROLE}</InputLabel>
              <Select id="select-role" label={ROLE} {...getFieldProps('role')} sx={{ textTransform: 'capitalize' }}>
                {roles.map((role, index) => (
                  <MenuItem key={index} value={role} sx={{ textTransform: 'capitalize' }}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(touched.role && errors.role) && <FormHelperText>{errors.role}</FormHelperText>}
            </FormControl>

            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label={EMAIL_ADDRESS}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label={PASSWORD}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              {ADD_NEW_USER}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
