import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  CircularProgress,
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  Toolbar,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { Alert, ModalAlert, Page } from '../components';
import { useAppContext } from '../context/app-context';
import {
  CORRECT_CHECKIN_OUT,
  CHECK_IN_OUT,
  UPDATE_CHECK_IN_OUT,
  ADD_CHECK_IN_OUT,
  USER,
  CHECK_IN,
  CHECK_OUT,
  START_BREAK,
  END_BREAK,
  UPDATE,
  ADD,
  NOTES,
} from '../lang/en';

const CustomStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'self-start',
  flexDirection: 'column',
  padding: theme.spacing(5, 2, 5, 2),
}));

const NewCheckInOut = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { showAlert, displayAlert, authFetch } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('');
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [description, setDescription] = useState('');
  const [activeBreak, setActiveBreak] = useState(false);
  const [breaks, setBreaks] = useState([{ active: false, startBreak: null, endBreak: null }]);

  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');

  const [incorrectValues, setIncorrectValues] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await authFetch.get(`user?limit=1000`);
        const { users } = data;
        setUsers(users);
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    const fetchCheckInOut = async () => {
      try {
        const { data } = await authFetch.get(`/check-in-out/${state?.checkInOutId}`);
        const {
          userId: { firstName, lastName },
          startTime,
          endTime,
          description,
          breaks,
          activeBreak,
        } = data;
        setUserFirstName(firstName);
        setUserLastName(lastName);
        setCheckIn(startTime);
        setCheckOut(endTime);
        setDescription(description);
        setBreaks(breaks);
        setActiveBreak(activeBreak);
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };

    if (state?.checkInOutId !== undefined) {
      fetchCheckInOut();
    } else {
      fetchUsers();
    }
  }, []);

  const createCheckInOut = async () => {
    try {
      let valid = breaks.every((itemBreak) => {
        return (
          moment(itemBreak.startBreak).format('HH:mm') > moment(checkIn).format('HH:mm') &&
          moment(itemBreak.startBreak).format('HH:mm') < moment(checkOut).format('HH:mm') &&
          moment(itemBreak.startBreak).format('HH:mm') < moment(itemBreak.endBreak).format('HH:mm') &&
          moment(itemBreak.endBreak).format('HH:mm') < moment(checkOut).format('HH:mm')
        );
      });

      valid = moment(checkIn).format() < moment(checkOut).format() && valid;

      if (!valid) {
        setIncorrectValues(true);
        return;
      }
      const newCheckInOut = await authFetch.post('/check-in-out/admin', {
        userId,
        startTime: checkIn,
        endTime: checkOut,
        description,
        breaks,
      });
      if (newCheckInOut !== undefined) {
        setTimeout(() => {
          navigate('/dashboard/check-in-outs', { replace: true });
        }, 3000);
        displayAlert('success', newCheckInOut?.data?.msg);
      }
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const updateCheckInOut = async (id) => {
    try {
      let valid = breaks.every((itemBreak) => {
        return (
          moment(itemBreak.startBreak).format('HH:mm') > moment(checkIn).format('HH:mm') &&
          moment(itemBreak.startBreak).format('HH:mm') < moment(checkOut).format('HH:mm') &&
          moment(itemBreak.startBreak).format('HH:mm') < moment(itemBreak.endBreak).format('HH:mm') &&
          moment(itemBreak.endBreak).format('HH:mm') < moment(checkOut).format('HH:mm')
        );
      });

      valid = moment(checkIn).format() < moment(checkOut).format() && valid;

      if (!valid) {
        setIncorrectValues(true);
        return;
      }

      const updatedCheckInOut = await authFetch.patch(`/check-in-out/admin/${id}`, {
        startTime: checkIn,
        endTime: checkOut,
        description,
        breaks,
      });

      displayAlert('success', updatedCheckInOut?.data?.msg);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  return (
    <>
      {showAlert && <Alert />}

      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <Page title={CHECK_IN_OUT}>
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {state?.checkInOutId ? UPDATE_CHECK_IN_OUT : ADD_CHECK_IN_OUT}
              </Typography>
            </Stack>
            <Card>
              <CustomStyle>
                {state?.checkInOutId ? (
                  <h3 style={{ marginBottom: '20px' }}>{`${moment(checkIn).format(
                    'LL'
                  )} - ${userFirstName} ${userLastName}`}</h3>
                ) : (
                  <div style={{ width: '250px' }}>
                    <FormControl fullWidth>
                      <InputLabel id="select-user-label">{USER}</InputLabel>
                      <Select
                        labelId="select-user"
                        id="select-user"
                        value={userId}
                        label={USER}
                        onChange={(e) => setUserId(e.target.value)}
                        MenuProps={{ PaperProps: { sx: { maxHeight: '50vh' } } }}
                      >
                        {users.map((user, i) => (
                          <MenuItem key={i} value={user._id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}

                {state?.checkInOutId ? (
                  <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4} mt={2}>
                    <div style={{ marginRight: '20px' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label={CHECK_IN}
                          ampm={false}
                          value={checkIn}
                          onChange={(newValue) => {
                            setCheckIn(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label={CHECK_OUT}
                          ampm={false}
                          disabled={activeBreak}
                          value={checkOut}
                          onChange={(newValue) => {
                            setCheckOut(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </Stack>
                ) : (
                  <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={2}>
                    <div style={{ marginRight: '20px' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label={CHECK_IN}
                          ampm={false}
                          value={checkIn}
                          onChange={(newValue) => {
                            setCheckIn(newValue);
                          }}
                          inputFormat="dd/MM/yyyy HH:mm"
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'dd/MM/yyyy hh:mm',
                                }}
                              />
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label={CHECK_OUT}
                          ampm={false}
                          value={checkOut}
                          onChange={(newValue) => {
                            setCheckOut(newValue);
                          }}
                          inputFormat="dd/MM/yyyy HH:mm"
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'dd/MM/yyyy hh:mm',
                                }}
                              />
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </Stack>
                )}

                {state?.checkInOutId ? (
                  breaks.map((item, index) => {
                    return (
                      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <div style={{ marginRight: '20px' }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              ampm={false}
                              label={`${START_BREAK} (${index + 1})`}
                              value={item?.startBreak}
                              onChange={(newValue) => {
                                setBreaks((prevBreaks) =>
                                  prevBreaks.map((itemBreak, i) => {
                                    if (i === index) {
                                      itemBreak.startBreak = newValue;
                                      return itemBreak;
                                    }
                                    return itemBreak;
                                  })
                                );
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </div>

                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              ampm={false}
                              label={`${END_BREAK} (${index + 1})`}
                              value={item?.endBreak}
                              onChange={(newValue) => {
                                setActiveBreak(false);
                                setBreaks((prevBreaks) =>
                                  prevBreaks.map((itemBreak, i) => {
                                    if (i === index) {
                                      const hour = moment(newValue).format('HH');
                                      const mins = moment(newValue).format('mm');

                                      itemBreak.endBreak = moment(itemBreak.startBreak).set({ h: hour, m: mins });
                                      itemBreak.active = false;
                                      return itemBreak;
                                    }
                                    return itemBreak;
                                  })
                                );
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </div>
                      </Stack>
                    );
                  })
                ) : (
                  <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <div style={{ marginRight: '20px' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          ampm={false}
                          label={START_BREAK}
                          value={breaks[0]?.startBreak}
                          onChange={(newValue) => {
                            setBreaks((prevBreaks) =>
                              prevBreaks.map((itemBreak, i) => {
                                if (i === 0) {
                                  itemBreak.startBreak = newValue;
                                  return itemBreak;
                                }
                                return itemBreak;
                              })
                            );
                          }}
                          // renderInput={(params) => <TextField {...params} />}
                          inputFormat="HH:mm"
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'hh:mm',
                                }}
                              />
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </div>

                    <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          ampm={false}
                          label={END_BREAK}
                          value={breaks[0]?.endBreak}
                          onChange={(newValue) => {
                            setActiveBreak(false);
                            setBreaks((prevBreaks) =>
                              prevBreaks.map((itemBreak, i) => {
                                if (i === 0) {
                                  const hour = moment(newValue).format('HH');
                                  const mins = moment(newValue).format('mm');

                                  itemBreak.endBreak = moment(itemBreak.startBreak).set({ h: hour, m: mins });
                                  itemBreak.active = false;
                                  return itemBreak;
                                }
                                return itemBreak;
                              })
                            );
                          }}
                          // renderInput={(params) => <TextField {...params} />}
                          inputFormat="HH:mm"
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'hh:mm',
                                }}
                              />
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </Stack>
                )}

                <div style={{ width: '100%', marginTop: '20px' }}>
                  <TextField
                    fullWidth
                    type="text"
                    label={NOTES}
                    value={description}
                    rows={4}
                    multiline
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {state?.checkInOutId ? (
                    <Button
                      sx={{ marginTop: '10px' }}
                      variant="outlined"
                      onClick={() => updateCheckInOut(state?.checkInOutId)}
                    >
                      {UPDATE}
                    </Button>
                  ) : (
                    <Button sx={{ marginTop: '10px' }} variant="outlined" onClick={createCheckInOut}>
                      {ADD}
                    </Button>
                  )}
                </div>
              </CustomStyle>
            </Card>
            {incorrectValues && <ModalAlert handleModalClose={setIncorrectValues} text={CORRECT_CHECKIN_OUT} />}
          </Container>
        </Page>
      )}
    </>
  );
};
export default NewCheckInOut;
