import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  CircularProgress,
  Grid,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
  Toolbar,
  styled,
} from '@mui/material';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab';

import { Alert, Page, Scrollbar } from '../components';
import { useAppContext } from '../context/app-context';
import {
  CHECK_IN,
  CHECK_OUT,
  START_BREAK,
  END_BREAK,
  DATE,
  WORK_HOURS,
  CHECK_IN_OUT,
  SAVE,
  NOTES,
  FROM_DATE,
  TO_DATE,
  RESET,
  NO_RESULTS_FOUND,
  TOTAL,
} from '../lang/en';

const TABLE_HEAD = [
  { id: 'createdDate', label: `${DATE}`, alignRight: false },
  { id: 'checkIn', label: `${CHECK_IN}`, alignRight: false },
  { id: 'checkOut', label: `${CHECK_OUT}`, alignRight: false },
  { id: 'workHours', label: `${WORK_HOURS}`, alignRight: false },
];

function CheckInOut() {
  const { showAlert, displayAlert, authFetch, user } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  const [checkInId, setCheckInId] = useState('');
  const [breakId, setBreakId] = useState('');
  const [startTime, setStartTime] = useState(' ');
  const [endTime, setEndTime] = useState(' ');
  const [attempt, setAttempt] = useState('0');
  const [active, setActive] = useState(false);
  const [activeBreak, setActiveBreak] = useState(false);
  const [breaks, setBreaks] = useState([]);
  const [description, setDescription] = useState('');

  const [coordinatesCheckIn, setCoordinatesCheckIn] = useState(null);
  const [addressCheckIn, setAddressCheckIn] = useState(null);

  const [coordinatesCheckOut, setCoordinatesCheckOut] = useState(null);
  const [addressCheckOut, setAddressCheckOut] = useState(null);
  const [newCheckInOut, setNewCheckInOut] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await authFetch.get(`/check-in-out/${user._id}/date`);
      setCheckInId(data?._id);
      setAttempt(data?.attempt);
      setActive(data?.active);
      setStartTime(data?.startTime);
      setEndTime(data?.endTime);
      setDescription(data?.description);

      setActiveBreak(data?.activeBreak);
      setBreaks(data?.breaks);
      setIsLoading(false);

      if (activeBreak) {
        const addBreak = data?.breaks?.length > 0 && data?.breaks[data?.breaks?.length - 1];
        setBreakId(addBreak);
      }
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };
  useEffect(() => {
    fetchData();
  }, [active, activeBreak]);

  const getLocationCheckIn = async () => {
    if (!navigator.geolocation) {
      displayAlert('danger', 'Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinatesCheckIn({ latitude: position?.coords?.latitude, longitude: position?.coords?.longitude });
        },
        () => {
          displayAlert('danger', 'Unable to retrieve your location, you have to enable Location on your settings.');
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 5000,
        }
      );
    }
  };
  const fetchAddressCheckIn = async () => {
    try {
      const { data } = await authFetch.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${coordinatesCheckIn?.latitude}&lon=${coordinatesCheckIn?.longitude}&format=json`
      );

      setAddressCheckIn(data?.address);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const handleCheckIn = async () => {
    try {
      const { data } = await authFetch.post('/check-in-out/', {
        userId: user._id,
        startTime: new Date(),
        startTimeLocation: {
          lat: coordinatesCheckIn?.latitude,
          long: coordinatesCheckIn?.longitude,
          address: {
            road: addressCheckIn.road ? addressCheckIn.road : '',
            city: addressCheckIn.town ? addressCheckIn.town : '',
          },
        },
      });
      setCheckInId(data._id);
      setAttempt(data.attempt);
      setActive(data.active);
      setStartTime(data.startTime);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };
  useEffect(() => {
    if (coordinatesCheckIn) {
      fetchAddressCheckIn();
    }
  }, [coordinatesCheckIn]);

  useEffect(() => {
    if (addressCheckIn) {
      handleCheckIn();
      setIsLoading(false);
      setNewCheckInOut(true);
    }
  }, [addressCheckIn]);

  const getLocationCheckOut = async () => {
    if (!navigator.geolocation) {
      displayAlert('danger', 'Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinatesCheckOut({ latitude: position?.coords?.latitude, longitude: position?.coords?.longitude });
        },
        () => {
          displayAlert('danger', 'Unable to retrieve your location, you have to enable Location on your settings.');
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 5000,
        }
      );
    }
  };
  const fetchAddressCheckOut = async () => {
    try {
      const { data } = await authFetch.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${coordinatesCheckOut?.latitude}&lon=${coordinatesCheckOut?.longitude}&format=json`
      );
      setAddressCheckOut(data?.address);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };
  const handleCheckOut = async () => {
    try {
      const { data } = await authFetch.patch('/check-in-out/', {
        checkInId,
        endTime: new Date(),
        endTimeLocation: {
          lat: coordinatesCheckOut?.latitude,
          long: coordinatesCheckOut?.longitude,
          address: {
            road: addressCheckOut.road ? addressCheckOut.road : '',
            city: addressCheckOut.town ? addressCheckOut.town : '',
          },
        },
      });
      setActive(data.active);
      setEndTime(data.endTime);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };
  useEffect(() => {
    if (coordinatesCheckOut) {
      fetchAddressCheckOut();
    }
  }, [coordinatesCheckOut]);
  useEffect(() => {
    if (addressCheckOut) {
      handleCheckOut();
      setIsLoading(false);
      setNewCheckInOut(true);
    }
  }, [addressCheckOut]);

  const checkInOutDescription = async () => {
    try {
      const descriptionSaved = await authFetch.patch('/check-in-out/description', {
        userId: user._id,
        startTime,
        description,
      });
      displayAlert('success', descriptionSaved?.data?.msg);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const handleStartBreak = async () => {
    try {
      const { data } = await authFetch.patch('/check-in-out/start-break', {
        checkInId,
        startBreak: new Date(),
      });
      setActiveBreak(true);
      const addBreak = data.breaks.length > 0 && data?.breaks[data.breaks.length - 1];
      if (addBreak) {
        setBreakId(addBreak._id);
      }
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const handleEndBreak = async () => {
    try {
      const { data } = await authFetch.patch('/check-in-out/end-break', {
        checkInId,
        breakId,
        endBreak: new Date(),
      });
      setActiveBreak(false);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const [checkInOuts, setCheckInOuts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCheckInOuts, setTotalCheckInOuts] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalSalary, setTotalSalary] = useState(0);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const fetchCheckInOuts = async () => {
    try {
      const fDate = fromDate === null ? '' : fromDate;
      const tDate = toDate === null ? '' : toDate;
      const { data } = await authFetch.get(`check-in-out/user/${user._id}?page=${page + 1}&from=${fDate}&to=${tDate}`);

      const { checkIns, totalCheckIns, totalHours, totalSalary } = data;
      setCheckInOuts(checkIns);
      setTotalCheckInOuts(totalCheckIns);
      setTotalHours(totalHours);
      setTotalSalary((+totalSalary).toFixed(2));
      setNewCheckInOut(false);
    } catch (error) {
      displayAlert('danger', error?.response?.data?.msg);
    }
  };
  useEffect(() => {
    fetchCheckInOuts();
  }, [page, fromDate, toDate, newCheckInOut]);

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };
  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    });
  };

  const emptyCheckInOuts = checkInOuts.length === 0;

  return (
    <>
      {showAlert && <Alert />}

      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title={CHECK_IN_OUT}>
            <Container>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Stack direction="row" alignItems="center" justifyContent={'space-between'} mb={2}>
                      {attempt === '0' && (
                        <Button
                          sx={{ marginLeft: '10px' }}
                          variant="contained"
                          onClick={() => {
                            setIsLoading(true);
                            getLocationCheckIn();
                          }}
                        >
                          {CHECK_IN}
                        </Button>
                      )}
                      {active && (
                        <Button
                          sx={{ marginLeft: '10px' }}
                          variant="outlined"
                          onClick={handleStartBreak}
                          disabled={activeBreak}
                        >
                          {START_BREAK}
                        </Button>
                      )}

                      {active && !activeBreak && (
                        <Button
                          sx={{ marginRight: '10px' }}
                          variant="contained"
                          onClick={() => {
                            setIsLoading(true);
                            getLocationCheckOut();
                          }}
                        >
                          {CHECK_OUT}
                        </Button>
                      )}

                      {activeBreak && (
                        <Button sx={{ marginRight: '10px' }} variant="outlined" onClick={handleEndBreak}>
                          {END_BREAK}
                        </Button>
                      )}
                    </Stack>

                    <Timeline>
                      {attempt === '1' && (
                        <TimelineItem>
                          <TimelineOppositeContent color="text.secondary">
                            {startTime ? moment(startTime).format('H:mm') : '-'}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="success" />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>{CHECK_IN}</TimelineContent>
                        </TimelineItem>
                      )}

                      {breaks &&
                        breaks.map((item, index) => (
                          <div key={index}>
                            <TimelineItem>
                              <TimelineOppositeContent color="text.secondary">
                                {item.startBreak ? moment(item.startBreak).format('H:mm') : '-'}
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                <TimelineDot color="warning" />
                                {!item.active && (
                                  <TimelineConnector sx={!item.active && { backgroundColor: '#FFC107' }} />
                                )}
                              </TimelineSeparator>
                              <TimelineContent>{START_BREAK}</TimelineContent>
                            </TimelineItem>
                            {!item.active && (
                              <TimelineItem>
                                <TimelineOppositeContent color="text.secondary">
                                  {item.endBreak ? moment(item.endBreak).format('H:mm') : '-'}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                  <TimelineDot color="warning" />
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>{END_BREAK}</TimelineContent>
                              </TimelineItem>
                            )}
                          </div>
                        ))}

                      {((active && !activeBreak) || (attempt === '1' && !active)) && (
                        <TimelineItem>
                          <TimelineOppositeContent color="text.secondary">
                            {attempt === '1' && !active ? moment(endTime).format('H:mm') : '-'}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color={attempt === '1' && !active ? 'success' : 'error'} />
                          </TimelineSeparator>
                          <TimelineContent>{CHECK_OUT}</TimelineContent>
                        </TimelineItem>
                      )}
                    </Timeline>
                    {attempt === '1' ? (
                      <>
                        <TextField
                          sx={{ marginTop: '20px' }}
                          fullWidth
                          type="text"
                          label={NOTES}
                          value={description}
                          rows={4}
                          multiline
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <Button
                          sx={{ marginTop: '10px', marginBottom: '20px' }}
                          variant="outlined"
                          onClick={checkInOutDescription}
                        >
                          {SAVE}
                        </Button>
                      </>
                    ) : (
                      ''
                    )}
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Card>
                      <div className="filter__control">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={FROM_DATE}
                            value={fromDate}
                            onChange={(newValue) => {
                              setFromDate(moment(newValue).format('YYYY-MM-DD'));
                            }}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'dd/MM/yyyy',
                                  }}
                                />
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="filter__control">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={TO_DATE}
                            value={toDate}
                            onChange={(newValue) => {
                              setToDate(moment(newValue).format('YYYY-MM-DD'));
                            }}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'dd/MM/yyyy',
                                  }}
                                />
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="filter__button">
                        <Button sx={{ lineHeight: '2.8' }} variant="outlined" onClick={handleReset}>
                          {RESET}
                        </Button>
                      </div>

                      <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {TABLE_HEAD.map((headCell) => (
                                  <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                                    {headCell.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {checkInOuts.map((checkInOut) => {
                                const { _id, date, startTime, endTime, workHours, suspect } = checkInOut;

                                return (
                                  <TableRow
                                    hover
                                    key={_id}
                                    tabIndex={-1}
                                    style={suspect ? { backgroundColor: '#ff000080' } : {}}
                                  >
                                    <TableCell align="left">{date}</TableCell>
                                    <TableCell align="left">{moment(startTime).format('HH:mm')}</TableCell>
                                    <TableCell align="left">{moment(endTime).format('HH:mm')}</TableCell>
                                    <TableCell align="left">{workHours !== undefined ? workHours : '-'}</TableCell>
                                  </TableRow>
                                );
                              })}
                              {!emptyCheckInOuts && (
                                <TableRow hover tabIndex={-1}>
                                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                    {TOTAL}
                                  </TableCell>
                                  <TableCell align="left" />
                                  <TableCell align="left" />
                                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                    {`${totalHours?.hours}h ${totalHours?.mins}m / ${totalSalary}`}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>

                            {emptyCheckInOuts && (
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                    <Typography variant="body2" align="center">
                                      {NO_RESULTS_FOUND}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Scrollbar>

                      <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={totalCheckInOuts}
                        rowsPerPage={10}
                        page={page}
                        onPageChange={handleChangePage}
                        showFirstButton
                        showLastButton
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Page>
        </>
      )}
    </>
  );
}

export default CheckInOut;
