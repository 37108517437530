import { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon, ListItemButton, ListItemText } from '@mui/material';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { useNavigate } from 'react-router-dom';
import './style.css';

import { CAR_WASH, CAR_TRANSFER } from '../lang/en';
import { useAppContext } from '../context/app-context';

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));
const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  left: '10px',
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = useState(null);

  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const { authFetch, user } = useAppContext();
  const [locations, setLocations] = useState([]);
  const [baseUrl, setBaseUrl] = useState([]);

  const navigate = useNavigate();
  const toCarWash = (locationId, locationName) => {
    navigate(`/${baseUrl}/cars-washed`, { state: { locationId, locationName } });
  };
  const toCarTransfer = (locationId, locationName) => {
    navigate(`/${baseUrl}/cars-transfered`, { state: { locationId, locationName } });
  };

  useEffect(() => {
    if (user?.role === 'admin') {
      setBaseUrl('dashboard');
    }
    if (user?.role === 'manager') {
      setBaseUrl('manager');
    }
    const fetchNameLocations = async () => {
      const { data } = await authFetch.get('location/admin');
      setLocations(data?.locations);
    };
    fetchNameLocations();
  }, []);

  return (
    <>
      <div>
        <ListItemStyle
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableelevation="true"
          onClick={handleClick}
        >
          <ListItemIconStyle>
            <FormatListNumberedIcon />
          </ListItemIconStyle>
          {CAR_WASH}
        </ListItemStyle>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {locations.map((location, index) => (
            <MenuItem onClick={handleClose} key={index} disableRipple>
              <ListItemText
                onClick={() => toCarWash(location?._id, location?.locationName)}
                primary={location?.locationName}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
      <div>
        <ListItemStyle
          id="demo-customized-button1"
          aria-controls={open1 ? 'demo-customized-menu1' : undefined}
          aria-haspopup="true"
          aria-expanded={open1 ? 'true' : undefined}
          variant="contained"
          disableelevation="true"
          onClick={handleClick1}
        >
          <ListItemIconStyle>
            <FormatListNumberedIcon />
          </ListItemIconStyle>
          {CAR_TRANSFER}
        </ListItemStyle>
        <StyledMenu
          id="demo-customized-menu1"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button1',
          }}
          anchorEl={anchorEl1}
          open={open1}
          onClose={handleClose1}
        >
          {locations.map(
            (location) =>
              location?.locationType === 'withTransfer' && (
                <MenuItem onClick={handleClose1} disableRipple>
                  <ListItemText
                    onClick={() => toCarTransfer(location?._id, location?.locationName)}
                    primary={location?.locationName}
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </MenuItem>
              )
          )}
        </StyledMenu>
      </div>
    </>
  );
}
