import { useState, useEffect } from 'react';
import { faker } from '@faker-js/faker';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Grid, Container, TextField, Card } from '@mui/material';

import { Alert, Page } from '../components';
import {
  AppCarTransferByTransferTypeStats,
  AppCarWashByLocationStats,
  AppInOutStats,
} from '../sections/@dashboard/app';
import { useAppContext } from '../context/app-context';
import { FROM_DATE, TO_DATE, HPZ, HBP, APDT, TRANSFR_KM } from '../lang/en';

const transferTypesNames = {
  hzp: HPZ,
  hbp: HBP,
  apdt: APDT,
  presumptive: TRANSFR_KM,
};

export default function DashboardApp() {
  const theme = useTheme();
  const { showAlert, displayAlert, authFetch, user } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  const [insOutsStats, setInsOutsStats] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  // const [carWashStats, setCarWashStats] = useState({
  //   title: 'C',
  //   labels: ['01/09/2022', '02/09/2022', '03/09/2022', '04/09/2022', '05/09/2022', '06/09/2022', '07/09/2022'],
  //   data: [
  //     {
  //       name: 'Dietlikon',
  //       type: 'column',
  //       fill: 'solid',
  //       data: [23, 11, 22, 27, 13, 22, 37],
  //     },
  //     {
  //       name: 'Kloten',
  //       type: 'area',
  //       fill: 'gradient',
  //       data: [44, 55, 41, 67, 22, 43, 21],
  //     },
  //     {
  //       name: 'Zurich',
  //       type: 'line',
  //       fill: 'solid',
  //       data: [30, 25, 36, 30, 45, 35, 64],
  //     },
  //   ],
  // });
  const [carWashStats, setCarWashStats] = useState(null);

  const [carTransferStats, setCarTransferStats] = useState(null);

  useEffect(() => {
    const fetchInsOuts = async () => {
      try {
        const fDate = fromDate === null ? '' : fromDate;
        const tDate = toDate === null ? '' : toDate;
        const {
          data: { totalStats },
        } = await authFetch.get(`dashboard/stats?from=${fDate}&to=${tDate}`);

        setInsOutsStats(totalStats);
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchInsOuts();
  }, [fromDate, toDate]);

  useEffect(() => {
    const fetchCarStats = async () => {
      try {
        const { data: data1 } = await authFetch.get(`dashboard/stats/car-wash`);
        setCarWashStats(data1);
        const { data: data2 } = await authFetch.get(`dashboard/stats/car-transfer`);
        setCarTransferStats(data2);
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchCarStats();
  }, []);

  return (
    <>
      {showAlert && <Alert />}
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title="Dashboard">
            <Container maxWidth="xl">
              <div className="filter__control" style={{ marginBottom: '15px' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={FROM_DATE}
                    value={fromDate}
                    onChange={(newValue) => {
                      setFromDate(moment(newValue).format('YYYY-MM-DD'));
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'dd/MM/yyyy',
                          }}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="filter__control">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={TO_DATE}
                    value={toDate}
                    onChange={(newValue) => {
                      setToDate(moment(newValue).format('YYYY-MM-DD'));
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'dd/MM/yyyy',
                          }}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <AppInOutStats
                    title={`${insOutsStats.carWash.totalCount} Car${
                      insOutsStats.carWash.totalCount > 1 ? 's' : ''
                    } Washed`}
                    total={insOutsStats.carWash.totalPrice}
                    icon={'ant-design:car-filled'}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <AppInOutStats
                    title={`${insOutsStats.carTransfer.totalCount} Car${
                      insOutsStats.carTransfer.totalCount > 1 ? 's' : ''
                    } Transfered`}
                    total={insOutsStats.carTransfer.totalPrice}
                    color="info"
                    icon={'ant-design:pull-request-outlined'}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <AppInOutStats
                    title={`${insOutsStats.checkInOut.totalCheckIns} Check In Outs`}
                    total={insOutsStats.checkInOut.totalCount}
                    color="error"
                    icon={'ant-design:check-circle-filled'}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <AppInOutStats
                    title="All"
                    total={
                      insOutsStats.carWash.totalPrice +
                      insOutsStats.carTransfer.totalPrice -
                      insOutsStats.checkInOut.totalCount
                    }
                    color="warning"
                    icon={'ant-design:appstore-add-outlined'}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={8}>
                  {carWashStats && (
                    <AppCarWashByLocationStats
                      title={carWashStats?.title}
                      chartLabels={carWashStats?.labels}
                      chartData={carWashStats?.data}
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  {carTransferStats && (
                    <AppCarTransferByTransferTypeStats
                      title={carTransferStats?.title}
                      chartData={carTransferStats?.carTransfer}
                      chartColors={[
                        theme.palette.primary.main,
                        theme.palette.chart.blue[0],
                        theme.palette.chart.violet[0],
                        theme.palette.chart.yellow[0],
                      ]}
                    />
                  )}
                </Grid>
              </Grid>
              {/*
          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
            </Container>
          </Page>
        </>
      )}
    </>
  );
}
