import { Iconify } from '../../components';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfigAdmin1 = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'check in / out',
    path: '/dashboard/check-in-out',
    icon: getIcon('eva:arrow-circle-down-fill'),
  },
  {
    title: 'all check ins / outs',
    path: '/dashboard/check-in-outs',
    icon: getIcon('eva:arrow-circle-down-fill'),
  },
  {
    title: 'my cars washed',
    path: '/dashboard/cars-washed-user',
    icon: getIcon('eva:car-fill'),
  },

  {
    title: 'my cars transfered',
    path: '/dashboard/cars-transfered-user',
    icon: getIcon('eva:flip-2-fill'),
  },
];
const navConfigAdmin2 = [
  {
    title: 'today plan',
    path: '/dashboard/plan',
    icon: getIcon('eva:external-link-outline'),
  },
  {
    title: 'payrolls',
    path: '/dashboard/payrolls',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'locations',
    path: '/dashboard/locations',
    icon: getIcon('eva:pin-fill'),
  },
];

const navConfigUser = [
  {
    title: 'check in/out',
    path: '/user/check-in-out',
    icon: getIcon('eva:arrow-circle-down-fill'),
  },

  {
    title: 'cars washed',
    path: '/user/cars-washed-user',
    icon: getIcon('eva:car-fill'),
  },

  {
    title: 'cars transfered',
    path: '/user/cars-transfered-user',
    icon: getIcon('eva:flip-2-fill'),
  },
  {
    title: 'today plan',
    path: '/user/plan',
    icon: getIcon('eva:external-link-outline'),
  },
];

const navConfigManager1 = [
  {
    title: 'check in / out',
    path: '/manager/check-in-out',
    icon: getIcon('eva:arrow-circle-down-fill'),
  },
  {
    title: 'my cars washed',
    path: '/manager/cars-washed-user',
    icon: getIcon('eva:car-fill'),
  },

  {
    title: 'my cars transfered',
    path: '/manager/cars-transfered-user',
    icon: getIcon('eva:flip-2-fill'),
  },
];

const navConfigManager2 = [
  {
    title: 'today plan',
    path: '/manager/plan',
    icon: getIcon('eva:external-link-outline'),
  },
];

const navConfigAccountant = [
  {
    title: 'payroll',
    path: '/accountant/payroll',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'payrolls',
    path: '/accountant/payrolls',
    icon: getIcon('eva:file-text-fill'),
  },
];

export { navConfigAdmin1, navConfigAdmin2, navConfigUser, navConfigManager1, navConfigManager2, navConfigAccountant };
