import { Stack, Divider, Typography } from '@mui/material';
import logo from '../../components/logo/rhr-logo.png';

export default function AuthSocial() {
  return (
    <>
      <Stack direction="row" spacing={1}>
        <div>
          <img
            src={logo}
            alt="logo"
            width="25%"
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%' }}
          />
        </div>
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {' '}
        </Typography>
      </Divider>
    </>
  );
}
