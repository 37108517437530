import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';

import { Page } from '../components';
import { PAGE_NOT_FOUND, CHECK_PAGE_NOT_FOUNDED, GO_HOME } from '../lang/en';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Page404() {
  return (
    <Page title="404 Page Not Found">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            {PAGE_NOT_FOUND}
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>{CHECK_PAGE_NOT_FOUNDED}</Typography>

          <Box
            component="img"
            src="/static/illustrations/illustration_404.svg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            {GO_HOME}
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
