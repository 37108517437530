import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import { useAppContext } from '../../../context/app-context';
import { Iconify } from '../../../components';
import { DELETE, DETAILS } from '../../../lang/en';

PayrollsMoreMenu.propTypes = {
  id: PropTypes.string,
  handleDelete: PropTypes.func,
};

export default function PayrollsMoreMenu({ id, handleDelete }) {
  const { user } = useAppContext();

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role === 'admin') {
      setBaseUrl('dashboard');
    }
    if (user?.role === 'accountant') {
      setBaseUrl('accountant');
    }
  }, []);

  const handleOpenDetails = (payrollId) => {
    navigate(`/${baseUrl}/payroll`, { state: { payrollId } });
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            setIsOpen(false);
            handleOpenDetails(id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:info-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={DETAILS} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            setIsOpen(false);
            handleDelete(id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={DELETE} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
