import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import { useAppContext } from '../../../context/app-context';
import { Iconify } from '../../../components';
import { DELETE, EDIT, VALID } from '../../../lang/en';

CarWashMoreMenu.propTypes = {
  id: PropTypes.string,
  handleDelete: PropTypes.func,
  suspect: PropTypes.bool,
  handleSuspect: PropTypes.func,
};

export default function CarWashMoreMenu({ id, handleDelete, suspect, handleSuspect }) {
  const { user } = useAppContext();

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [baseUrl, setBaseUrl] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role === 'admin') {
      setBaseUrl('dashboard');
    }
    if (user?.role === 'manager') {
      setBaseUrl('manager');
    }
  }, []);

  const toEditCarWash = (carWashId) => {
    navigate(`/${baseUrl}/new-car-wash`, { state: { carWashId } });
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {suspect && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              setIsOpen(false);
              handleSuspect(id);
            }}
          >
            <ListItemIcon>
              <Iconify icon="eva:done-all-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={VALID} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => toEditCarWash(id)}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={EDIT} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            setIsOpen(false);
            handleDelete(id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={DELETE} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
