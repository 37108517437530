import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Link,
  Stack,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Iconify } from '../../../components';
import { useAppContext } from '../../../context/app-context';
import {
  EMAIL_ADDRESS,
  EMAIL_VALID,
  EMAIL_IS_REQUIRED,
  PASSWORD,
  PASSWORD_IS_REQUIRED,
  LOGIN_FORGET_PASSWORD,
  LOGIN_BUTTON,
} from '../../../lang/en';

const urlResetPassword = 'https://rhrcarcare-api.herokuapp.com/api/v1/auth/reset-password/link';

export default function LoginForm() {
  const { isLoading, loginUser } = useAppContext();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(EMAIL_VALID).required(EMAIL_IS_REQUIRED),
    password: Yup.string().required(PASSWORD_IS_REQUIRED),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      const currentUser = { email: formik.values.email, password: formik.values.password };
      loginUser(currentUser, setSubmitting);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const [passwordModal, setPasswordModal] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [successText, setSuccessText] = useState('');

  const handleClose = () => {
    setPasswordModal(false);
    setResetEmail('');
    setErrorEmail(false);
    setErrorText('');
    setSuccessText('');
  };
  const handleConfirm = async () => {
    try {
      const resetPassword = await axios.patch(urlResetPassword, {
        email: resetEmail,
      });

      setTimeout(() => {
        setPasswordModal(false);
        setResetEmail('');
        setSuccessText('');
      }, 3000);

      setErrorEmail(false);
      setErrorText('');
      setSuccessText(resetPassword?.data?.msg);
    } catch (error) {
      setErrorEmail(true);
      setErrorText(error?.response?.data?.msg);
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="e-mail"
              type="email"
              label={EMAIL_ADDRESS}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label={PASSWORD}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Link variant="subtitle2" underline="hover" href="#" onClick={() => setPasswordModal(true)}>
              {LOGIN_FORGET_PASSWORD}
            </Link>
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {LOGIN_BUTTON}
          </LoadingButton>
        </Form>
      </FormikProvider>
      <Dialog
        open={passwordModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reset password</DialogTitle>
        <DialogContent id="alert-dialog-content">
          <DialogContentText>
            Enter the email address associated with your account, and we’ll email you a link to reset your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email-adress"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            error={errorEmail}
            helperText={errorText}
          />
          {successText !== '' && <Alert severity="success">{successText}</Alert>}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
