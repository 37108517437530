import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import { Iconify } from '../../../components';
import { DELETE, EDIT, DETAILS } from '../../../lang/en';

CheckInOutMoreMenu.propTypes = {
  id: PropTypes.string,
  handleDelete: PropTypes.func,
  handleOpenDetails: PropTypes.func,
};

export default function CheckInOutMoreMenu({ id, handleDelete, handleOpenDetails }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toEditCheckInOut = (checkInOutId) => {
    navigate('/dashboard/new-check-in-out', { state: { checkInOutId } });
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            setIsOpen(false);
            handleOpenDetails(id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:info-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={DETAILS} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => toEditCheckInOut(id)}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={EDIT} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            setIsOpen(false);
            handleDelete(id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={DELETE} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
