import React from 'react';
// import CssBaseline from '@material-ui/core/CssBaseline';
import { CssBaseline, Typography, Container, Divider, Card } from '@mui/material';

import { RegisterForm } from '../sections/auth/register';
import { ADD_NEW_USER } from '../lang/en';

export default function SignIn() {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div>
        <Typography variant="h4" align="center" gutterBottom component="div">
          {ADD_NEW_USER}
          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {' '}
            </Typography>
          </Divider>
        </Typography>
        <Card sx={{ p: 3 }}>
          <RegisterForm />
        </Card>
      </div>
    </Container>
  );
}
