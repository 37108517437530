import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Iconify } from '../../../components';
import { useAppContext } from '../../../context/app-context';
import {
  PASSWORD_IS_REQUIRED,
  NEW_PASSWORD_IS_REQUIRED,
  NEW_AND_CONFIRM_PASSWORDS,
  CURRENT_PASSWORD,
  NEW_PASSWORD,
  CONFRIM_NEW_PASSWORD,
  UPDATE_PASSWORD,
} from '../../../lang/en';

ProfilePasswordForm.propTypes = {
  userId: PropTypes.string,
};

export default function ProfilePasswordForm({ userId }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const { authFetch, displayAlert, user } = useAppContext();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleNewShowPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleShowConfirmedPassword = () => {
    setShowConfirmedPassword((show) => !show);
  };

  const ProfilePasswordSchema = Yup.object().shape({
    // password: Yup.string().required(PASSWORD_IS_REQUIRED),
    newPassword: Yup.string().required(NEW_PASSWORD_IS_REQUIRED),
    confirmedPassword: Yup.string().when('newPassword', {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('newPassword')], NEW_AND_CONFIRM_PASSWORDS),
    }),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmedPassword: '',
    },
    validationSchema: ProfilePasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      (async () => {
        try {
          const changePassord = {
            currentPassword: formik.values.password,
            newPassword: formik.values.newPassword,
            confirmPassword: formik.values.confirmedPassword,
          };
          const changedPassord = await authFetch.patch(`user/${userId}/change-password`, changePassord);
          if (changedPassord !== undefined) {
            displayAlert('success', changedPassord.data.msg, setSubmitting);
          }
        } catch (error) {
          displayAlert('danger', error?.response?.data?.msg, setSubmitting);
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {user?.role !== 'admin' && (
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={CURRENT_PASSWORD}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            )}

            <TextField
              fullWidth
              type={showNewPassword ? 'text' : 'password'}
              label={NEW_PASSWORD}
              {...getFieldProps('newPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleNewShowPassword} edge="end">
                      <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />

            <TextField
              fullWidth
              type={showConfirmedPassword ? 'text' : 'password'}
              label={CONFRIM_NEW_PASSWORD}
              {...getFieldProps('confirmedPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowConfirmedPassword} edge="end">
                      <Iconify icon={showConfirmedPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmedPassword && errors.confirmedPassword)}
              helperText={touched.confirmedPassword && errors.confirmedPassword}
            />

            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              {UPDATE_PASSWORD}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
