import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
} from '@mui/material';

import { Alert, Page, Scrollbar, Iconify, AlertDialog, AlertDialogText } from '../components';
import { LocationMoreMenu } from '../sections/@dashboard/locations';
import { useAppContext } from '../context/app-context';
import {
  USERS,
  LOCATION,
  TYPE,
  CAR_TYPES,
  NEW_LOCATION,
  ALL_LOCATIONS,
  NO_RESULTS_FOUND,
  ARE_SURE_RESTORE_LOCATION,
  TRASH,
} from '../lang/en';

const TABLE_HEAD = [
  { id: 'locationId', label: `${LOCATION}`, alignRight: false },
  { id: 'locationType', label: `${TYPE}`, alignRight: false },
  { id: 'carTypesNumber', label: `${CAR_TYPES}`, alignRight: false },
  { id: 'users', label: `${USERS}`, alignRight: false },
];

const AllLocations = () => {
  const { showAlert, displayAlert, authFetch } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [totalLocations, setTotalLocations] = useState(0);
  const [locationsTrash, setLocationsTrash] = useState(false);

  const [locationId, setLocationId] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isRestoreOpen, setIsRestoreOpen] = useState(false);
  const [hasDeletedRestored, setHasDeletedRestored] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const { data } = await authFetch.get(`location?page=${page + 1}&deleted=${locationsTrash}`);
        const { locations, totalLocations } = data;
        setLocations(locations);
        setTotalLocations(totalLocations);
        setHasDeletedRestored(false);
        setIsLoading(false);
      } catch (error) {
        displayAlert('danger', error?.response?.data?.msg);
      }
    };
    fetchLocations();
  }, [page, locationsTrash, hasDeletedRestored]);

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (deleteId) => {
    setIsDeleteOpen(true);
    setLocationId(deleteId);
  };
  const handleClose = () => {
    setIsDeleteOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const locationDelete = locationsTrash
        ? await authFetch.delete(`location/${locationId}/permanently`)
        : await authFetch.delete(`location/admin/${locationId}`);

      setHasDeletedRestored(true);
      setIsDeleteOpen(false);

      displayAlert('success', locationDelete?.data?.msg);
    } catch (error) {
      setIsDeleteOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const handleRestore = (restoreId) => {
    setIsRestoreOpen(true);
    setLocationId(restoreId);
  };
  const handleCloseRestore = () => {
    setIsRestoreOpen(false);
  };
  const handleConfirmRestore = async () => {
    try {
      const locationRestore = await authFetch.patch(`location/${locationId}/restore`);

      setIsRestoreOpen(false);
      setHasDeletedRestored(true);

      displayAlert('success', locationRestore?.data?.msg);
    } catch (error) {
      setIsRestoreOpen(false);
      displayAlert('danger', error?.response?.data?.msg);
    }
  };

  const emptyLocations = locations.length === 0;

  return (
    <>
      {showAlert && <Alert />}
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title={LOCATION}>
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  {ALL_LOCATIONS}
                </Typography>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/dashboard/new-location"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {NEW_LOCATION}
                </Button>
              </Stack>

              <Card>
                <Button
                  className={`filter__Button ${!locationsTrash && 'active'}`}
                  variant="outlined"
                  onClick={() => setLocationsTrash(false)}
                >
                  {'Locations'}
                </Button>
                <Button
                  className={`filter__Button ${locationsTrash && 'deleted'}`}
                  variant="outlined"
                  color="error"
                  onClick={() => setLocationsTrash(true)}
                >
                  {TRASH}
                </Button>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {TABLE_HEAD.map((headCell) => (
                            <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {locations.map((location) => {
                          const { _id, locationName, locationType, carType, usersCount } = location;

                          return (
                            <TableRow hover key={_id} tabIndex={-1}>
                              <TableCell align="left">{locationName}</TableCell>
                              <TableCell align="left">{locationType}</TableCell>
                              <TableCell align="left">{carType.length}</TableCell>
                              <TableCell align="left">{usersCount}</TableCell>
                              <TableCell align="right">
                                <LocationMoreMenu
                                  id={_id}
                                  handleDelete={handleDelete}
                                  trash={locationsTrash}
                                  handleRestore={handleRestore}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>

                      {emptyLocations && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Typography variant="body2" align="center">
                                {NO_RESULTS_FOUND}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={totalLocations}
                  rowsPerPage={10}
                  page={page}
                  onPageChange={handleChangePage}
                  showFirstButton
                  showLastButton
                />
              </Card>
              {isDeleteOpen && (
                <AlertDialog handleClose={handleClose} handleConfirmDelete={handleConfirmDelete} text="location" />
              )}
              {isRestoreOpen && (
                <AlertDialogText
                  handleClose={handleCloseRestore}
                  handleConfirm={handleConfirmRestore}
                  text={ARE_SURE_RESTORE_LOCATION}
                />
              )}
            </Container>
          </Page>
        </>
      )}
    </>
  );
};
export default AllLocations;
