import React from 'react';
import logo from './logo/rhr-logo.png';

function Logo() {
  return (
    <div>
      <img src={logo} alt="logo rhr" style={{ width: '100px' }} />
    </div>
  );
}

export default Logo;
